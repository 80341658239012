import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField} from '@mui/material';
import { formatAmountWith2Decimals } from '../../../util/formatHelper';
import { formatIntegerAmount } from '../../../util/formatHelper';
import { formatNumber } from '../../../util/index';
import { PurchReqArticleStructure } from '../Store/PafContext';
import { initPurchReqArticleStructure } from '../Utils/ClearPurchReqStructure';

export default function EditManualArticle({requestType, inputMode, inputRow, onUpdatePurchReqArticleStructure}) {

    const [manualArticleDescription, setManualArticleDescription] = useState('');
    const [number, setNumber] = useState(0);
    const [numberAlfa, setNumberAlfa] = useState('');
    const [ordQty, setOrdQty] = useState(0);
    const [ordQtyAlfa, setOrdQtyAlfa] = useState('');
    const [priceLabel, setPriceLabel] = useState('Prijs per stuk');
    const [price, setPrice] = useState(0);
    const [priceAlfa, setPriceAlfa] = useState('');
    const [gbkDropDownList, setGbkDropDownList] = useState([]);
    const [spDropDownList, setSpDropDownList] = useState([]);
    const [selectedSpArticle, setSelectedSpArticle] = useState(null);
    const [selectedGbkArticle, setSelectedGbkArticle] = useState(null);
    const [purchReqArticleStructure, setPurchReqArticleStructure] = useState(PurchReqArticleStructure);
    const [descriptionEnabled, setDescriptionEnabled] = useState(true);

    const createGbkDropDownList = (gbkList) => {
        const newList = [];
        for (let i = 0; i < gbkList.length; i++) {
            const item = gbkList[i];
            newList.push({
            ledgerCode: item.LedgerCode,
            description: item.Description
            })
        }
        newList.sort( (a, b) => a.description.localeCompare(b.description));
        newList.sort( (a, b) => a.description.localeCompare(b.description));

        return newList;
    }

    const createSpDropDownList = (spList) => {
        const newList = [];
        for (let i = 0; i < spList.length; i++) {
            const item = spList[i];
            newList.push({
                partCode: item.PartCode,
                description: item.Description,
                spPlanGrpCode: item.PlanGrpCode
            })
        }
        newList.sort( (a, b) => a.description.localeCompare(b.description));
        newList.sort( (a, b) => a.description.localeCompare(b.description));

        return newList;
  }

    const handleSelectedGbkArticle = (option) => {
        let newPurchReqArticleStructure = {...purchReqArticleStructure};

        if (!option) {
            setSelectedGbkArticle(null);
            newPurchReqArticleStructure.manualArticle.gbkNumber = null;
            newPurchReqArticleStructure.manualArticle.gbkNumberDescription = null;
            onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
            return;
        }
        setSelectedGbkArticle(option);
        newPurchReqArticleStructure.manualArticle.gbkNumber = option.ledgerCode.trim();
        newPurchReqArticleStructure.manualArticle.gbkNumberDescription = option.description.trim();
        newPurchReqArticleStructure.manualArticle.spNumber = null;
        newPurchReqArticleStructure.manualArticle.spNumberDescription = null;
        newPurchReqArticleStructure.manualArticle.spPlanGrpCode = null;
        setPurchReqArticleStructure(newPurchReqArticleStructure);
        // send newPurchReqArticleStructure to component above
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const handleSelectedSpArticle = (option) => {
        let newPurchReqArticleStructure = {...purchReqArticleStructure};

        if (!option) {
            setSelectedSpArticle(null);
            newPurchReqArticleStructure.manualArticle.spNumber = '';
            newPurchReqArticleStructure.manualArticle.spNumberDescription = '';
            setPurchReqArticleStructure(newPurchReqArticleStructure);
            onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
            return;
        }
        setSelectedSpArticle(option);
        newPurchReqArticleStructure.manualArticle.spNumber = option.partCode.trim();
        newPurchReqArticleStructure.manualArticle.spNumberDescription = option.description.trim();
        newPurchReqArticleStructure.manualArticle.spPlanGrpCode = option.spPlanGrpCode.trim();
        newPurchReqArticleStructure.manualArticle.gbkNumber = '';
        newPurchReqArticleStructure.manualArticle.gbkNumberDescription = '';
        setPurchReqArticleStructure(newPurchReqArticleStructure);
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const updateManualArticleDescription = (event) => {
        setManualArticleDescription(event.target.value);
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.description = event.target.value;
        setPurchReqArticleStructure(newPurchReqArticleStructure);
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const handleNumber = (event) => {
        let input = event.target.value.trim();

        const formattedNumber = formatIntegerAmount(input);
        setNumberAlfa(formattedNumber);

        let newNumber = 0;
        if (formattedNumber.length > 0) {
        newNumber = +formattedNumber;
        }
        setNumber(newNumber);

        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.number = +newNumber;
        newPurchReqArticleStructure.totalPrice = newNumber * (newPurchReqArticleStructure.manualArticle.pricePerOrdQty / ordQty);
        setPurchReqArticleStructure(newPurchReqArticleStructure);
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const handleOrdQty = (event) => {
        let input = event.target.value.trim();

        const formattedOrdQty = formatIntegerAmount(input);
        setOrdQtyAlfa(formattedOrdQty);

        let newOrdQty = 0;
        if (formattedOrdQty.length > 0) {
        newOrdQty = +formattedOrdQty;
        }
        setOrdQty(newOrdQty);

        setPriceLabel('Prijs per ' + newOrdQty + ' stuks (*)');
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.ordQty = +newOrdQty;
        newPurchReqArticleStructure.manualArticle.totalPrice = number * (newPurchReqArticleStructure.manualArticle.pricePerOrdQty  / ordQty) ;
        setPurchReqArticleStructure(newPurchReqArticleStructure);
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const handlePrice = (event) => {
        let input = event.target.value.trim();

        const formattedAmount = formatAmountWith2Decimals(input);
        setPriceAlfa(formattedAmount);

        let priceNumeric = 0;
        if (formattedAmount.length === 0) {
            setPrice(0);
        } else {
            const priceString = input.replace(',', '.');
            priceNumeric = parseFloat(priceString).toFixed(2);
            setPrice(priceNumeric);
        }

        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.manualArticle.pricePerOrdQty = priceNumeric;
        newPurchReqArticleStructure.totalPrice = newPurchReqArticleStructure.number * (priceNumeric / ordQty);
        setPurchReqArticleStructure(newPurchReqArticleStructure);
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    useEffect(() => {
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        initPurchReqArticleStructure(newPurchReqArticleStructure, 'manual');

        setDescriptionEnabled(true);
        const fetchData = async () => {
            try {
                const gbkList = await axios.get(process.env.REACT_APP_API_URL + '/isah/ledger/getListForPaf');
                const spList = await axios.get(process.env.REACT_APP_API_URL + '/isah/article/getSpList');

                setGbkDropDownList(createGbkDropDownList(gbkList.data));
                setSpDropDownList(createSpDropDownList(spList.data));

                newPurchReqArticleStructure.articleType = 'manual';
                if (inputMode === 'update') {
                    setDescriptionEnabled(false);
                    setManualArticleDescription(inputRow.description);
                    setNumber(inputRow.number);
                    setNumberAlfa(inputRow.number.toString());
                    setPrice(inputRow.price);
                    setPriceAlfa(inputRow.price.toFixed(2).replace('.', ','));
                    setOrdQty(inputRow.ordQty);
                    setOrdQtyAlfa(inputRow.ordQty.toString());
                    setPriceLabel('Prijs per ' + inputRow.ordQty + ' stuks');

                    if (requestType === 'cost') {
                        setSelectedGbkArticle({ledgerCode: inputRow.gbkNumber, description: inputRow.gbkNumberDescription});
                    } else {
                        setSelectedSpArticle({spPlanGrpCode: inputRow.spPlanGrpCode, partCode: inputRow.spNumber, description: inputRow.spNumberDescription})
                    }

                    newPurchReqArticleStructure.description = inputRow.description;
                    newPurchReqArticleStructure.number = inputRow.number;
                    newPurchReqArticleStructure.ordQty = inputRow.ordQty;
                    newPurchReqArticleStructure.totalPrice = inputRow.totalPrice;
                    newPurchReqArticleStructure.manualArticle = {
                    pricePerOrdQty: inputRow.price,
                    gbkNumber: (requestType === 'cost') ? inputRow.gbkNumber : null,
                    gbkNumberDescription: (requestType === 'cost') ? inputRow.gbkNumberDescription : null,
                    spNumber: (requestType !== 'cost') ? inputRow.spNumber : null,
                    spNumberDescription: (requestType !== 'cost') ? inputRow.spNumberDescription : null,
                  };
                }
                setPurchReqArticleStructure(newPurchReqArticleStructure);
                // send newPurchReqArticleStructure to component above
                onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);

            } catch (error) {
                console.error('Kan geen GBK-rekeningen / SP-rekeningen ophalen', error);
            }
        };
        fetchData();
    } , []);

    return (
        <>
            { requestType === 'cost' ?
                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.ledgerCode
                        ? option.ledgerCode.trim() === value.ledgerCode.trim()
                        : true
                    }
                    // 'disableClearable' zou logisch zijn bij dit verplichte veld
                    // Echter 'disableClearable' gbruiken we niet, omdat de gebruiker over het veld heen moet kunnen typen bij het zoeken
                    // disableClearable
                    disablePortal
                    id="gbk-ledger-auto-complete"
                    options={gbkDropDownList}

                    // gbk.partCode is not defined when a manual
                    // article is selected in the list, which has no gbk number.
                    getOptionLabel = {(gbk) => gbk.ledgerCode ?
                        gbk.description.trim() + ' - ' + gbk.ledgerCode.trim()
                    :
                        ''
                    }
                    sx={{ width: 400 }}
                    onChange={(event, option) => {
                        handleSelectedGbkArticle(option);
                    }}
                    value={selectedGbkArticle || null}
                    renderInput={(params) => <TextField {...params} label="GBK nummer (*)" />}
                />
          : requestType === 'project' || 'investment-application' ?
                <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                        option.partCode
                        ? option.partCode.trim() === value.partCode.trim()
                        : true
                    }
                    // disableClearable // Gebruiken we niet, omdat de gebruiker over het veld heen moet kunnen typen bij het zoeken
                    disablePortal
                    id="sp-number-auto-complete"
                    options={spDropDownList}
                    getOptionLabel = {
                        (sp) => sp.partCode
                        ?  sp.spPlanGrpCode + ' -  ' + sp.description.trim() + ' - ' + sp.partCode.trim()
                        : ''
                    }
                    sx={{ width: 400 }}
                    onChange={(event, option) => {
                        handleSelectedSpArticle(option);
                    }}
                    value={selectedSpArticle || null}
                    renderInput={(params) => <TextField {...params} label="SP nummer (*)" />}
                />
          : null
        }
            <div>
                <TextField
                    // Let op: altijd value EN onChange gebruiken, anders wordt het veld geblokkeerd
                    value= {manualArticleDescription}
                    disabled = {!descriptionEnabled}
                    onChange={updateManualArticleDescription}
                    label="Artikelomschrijving (*)"
                    variant="outlined"
                    className="Textfield TextfieldExtraLarge"
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Aantal (*)"
                    value= {numberAlfa}
                    onChange={handleNumber}
                    className="TextfieldMedium"
                />

                <TextField
                    label="Bestelhoeveelheid (*)"
                    value= {ordQtyAlfa}
                    onChange={handleOrdQty}
                    className="TextfieldMedium"
                />
            </div>
            <div>
                <TextField
                    label= {priceLabel}
                    value= {priceAlfa}
                    onChange={handlePrice}
                    className="TextfieldLarge"
                />
            </div>
            <p>Totale prijs voor {number} stuks: {ordQty > 0 ? formatNumber(number * (price / ordQty), 2, 2) : 0}</p>
        </>
    )
}
