import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '../../components/ui/Button';

export default function PriceSetting(props) {
    const { id } = useParams();
    const [setting, setSetting] = useState();
    const { register, handleSubmit, setValue } = useForm();

    const onSubmit = async data => {
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + "/demeeuw/cto/pricesetting/" + id, data);
        } catch (error) {
            console.error("Error:", error);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/demeeuw/cto/pricesetting/" + id);
                setSetting(response.data);
                setValue("setting_type", response.data.setting_type || '');
                setValue("setting_name", response.data.setting_name || '');
                setValue("setting_value", response.data.setting_value || '');
                setValue("description", response.data.description || '');
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
    }, [id])

    return (
        <div>
            <h1>Prijs instelling: {setting?.name}</h1>
            <form className="Form">
                <div className="form-item">
                    <input
                        type="text"
                        defaultValue={setting?.country}
                        disabled
                    />
                    <label>Land</label>
                </div>
                <div className="form-item">
                    <input
                        type="text"
                        {...register("setting_type")}
                    />
                    <label>Type</label>
                </div>
                <div className="form-item">
                    <input
                        type="text"
                        {...register("setting_name")}
                    />
                    <label>Naam</label>
                </div>
                <div className="form-item">
                    <input
                        type="text"
                        {...register("setting_value")}
                    />
                    <label>Waarde</label>
                </div>
                <div className="form-item full-width">
                    <input
                        type="text"
                        {...register("description")}
                    />
                    <label>Beschrijving</label>
                </div>
                <div>
                    <Button onClick={handleSubmit(onSubmit)}>Opslaan</Button>
                </div>
            </form>
        </div>
    )
}
