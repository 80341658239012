import React from 'react';
import { FormControlLabel } from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function SelectRequestType({requestType, onSelectRequestType})   {
    return (
        <RadioGroup
            row
            onChange={(event) => onSelectRequestType(event.target.value)}
            value={requestType}
        >
            <FormControlLabel value="cost" control={<Radio />} label="Kosten" />
            <FormControlLabel value="project" control={<Radio />} label="Project" />
            <FormControlLabel value="investment-application" control={<Radio />} label="Investeringsaanvraag" />
        </RadioGroup>
    );
}
