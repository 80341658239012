import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function Configurator() {
    const { productId } = useParams();
    // const [product, setProduct] = useState(null);
    // const [scriptLoaded, setScriptLoaded] = useState(false);
    const [config, setConfig] = useState(null);
    useEffect((config) => {
    //     const fetchData = async () => {
    //         try {
    //   //        const productResponse = await axios.get(process.env.REACT_APP_API_URL + "/kbmax/quote/" + quoteId);
    //           setQuote(productResponse.data);
    //         } catch (error) {
    //           console.error("Error:", error);
    //         }
    //       };

    //     fetchData();

        const script = document.createElement('script');
        script.src = "https://demeeuw-dev.kbmax.com/embed.min.js";
        script.async = true;
        script.onload = () => {
            if (window.kbmax) {
                var configInstance = new window.kbmax.ConfiguratorEmbed({
                    elementId: "viewer",
                    configuratorId: 212, // 212 - 315
                    configuredProduct: productId,   // The same ID as the quote product id,
                    showHeader: true, // KBMax main app bar
                    showDrawer: true,
                    showMove: false,
                    showConfigHeader: true,  // Subheader
                    // bindToFormSelector: "custom-kbmax-form",
                    loadStyle: "none",
                });
                setConfig(configInstance);
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [productId]);

    if(config) {
        config.getFields(function(o){
            // console.log(o)
            //o will contain the field names and values
        });
    }

    return (
        <div id="viewer" style={{
            width: 'calc(100% + 48px)',
            marginTop: "-24px",
            marginLeft: "-24px",
            marginRight: "-24px",
            marginBottom: "-24px",
            height: 'calc(100vh - 64px)',
            overflow: 'hidden'
        }}></div>
    )
}
