import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel} from '@mui/material'
import TextField from '@mui/material/TextField';
import {constDeMeeuwAddress} from '../constants/constants';
import Button from '../ui/Button';
import DividerLine from '../ui/DividerLine';
import { PurchReqStructure } from './Store/PafContext';
import { useTranslation } from 'react-i18next';
import Popup from '../ui/Popup';

export default function DeliveryAddress()   {

  const navigate =  useNavigate();
  const { t } = useTranslation();

  const [showButtonGotoArticles, setShowButtonGotoArticles] = useState(false);
  const [deliveryAddressOption, setDeliveryAddressOption] = useState(null);

  const [deliveryAddressText, setDeliveryAddressText] = useState('');
  const [deliveryAddressInstruction, setDeliveryAddressInstruction] = useState('');

  const [popupActive, setPopupActive] = useState(false);
  const [popupText, setPopupText] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  const [purchReqStructure, setPurchReqStructure] = useState(PurchReqStructure);
  const location = useLocation(); // gekoppeld aan 'state' bij het doorgeven van deze link

  const popupCallback = (action) => {
    setPopupText('');
    setPopupTitle('');
    setPopupActive(false);
    if (action === 'confirm') {
      navigate('/inkoop-aanvraag');
    }
  }

  const gotoMainScreen = () => {
    setPopupTitle(t('Terug naar kostenplaats / project / leverancier'));
    setPopupText(t('Alle door u ingevoerde gegevens gaan verloren. Weet u het zeker?'));
    setPopupActive(true);
  }

  const handleDeliveryAddressOption = (event) => {
    let newPurchReqStructure = {...purchReqStructure};
    setDeliveryAddressOption(event.target.value);
    newPurchReqStructure.deliveryAddress.option = event.target.value;
    setDeliveryAddressInstruction('');
    newPurchReqStructure.deliveryAddress.instruction = '';

    if (event.target.value === 'notAppl') {
      setDeliveryAddressText('');
      newPurchReqStructure.deliveryAddress.address = '';

    } else if (event.target.value === 'dmo')  {
      setDeliveryAddressText(constDeMeeuwAddress);
      newPurchReqStructure.deliveryAddress.address = constDeMeeuwAddress;

    } else if (event.target.value === 'alt') {
      setDeliveryAddressText('');
      newPurchReqStructure.deliveryAddress.address = '';
    }

    determineIfButtonGotoArticlesMustBeShown(newPurchReqStructure);
    setPurchReqStructure(newPurchReqStructure);
  };

  const handleDeliveryAddressText = (event) => {
    let newPurchReqStructure = {...purchReqStructure};
    setDeliveryAddressText(event.target.value);
    newPurchReqStructure.deliveryAddress.address = event.target.value;
    determineIfButtonGotoArticlesMustBeShown(newPurchReqStructure);
    setPurchReqStructure(newPurchReqStructure);
  };

  const handleDeliveryAddressInstruction = (event) => {
    let newPurchReqStructure = {...purchReqStructure};
    setDeliveryAddressInstruction(event.target.value);
    newPurchReqStructure.deliveryAddress.instruction = event.target.value;
    setPurchReqStructure(newPurchReqStructure);
  };

  const requestTypeDescription = (purchReqStructure) => {
    if (purchReqStructure.requestType === 'cost') {
      return 'Type verzoek: Kosten';
    } else  if (purchReqStructure.requestType === 'project') {
      return 'Type verzoek: Project';
    }  else  if (purchReqStructure.requestType === 'investment-application') {
      return 'Type verzoek: Investeringsaanvraag (GBK0291)';
    }
  }

    const generalDescription = () => {
        if (purchReqStructure.project.vNumber) {
            return 'Project ' + purchReqStructure.project.fullDescription
        }
        if (purchReqStructure.costCenter.code) {
            return 'Kostenplaats ' + purchReqStructure.costCenter.fullDescription
        }
    }

    const vendorNameAndAddress = () => {
        return purchReqStructure.vendor.name + ' - ' + purchReqStructure.vendor.addr + ' - ' +  purchReqStructure.vendor.city;
    }

    const gotoArticleScreen = () => {
        navigate(`/inkoop-aanvraag/artikelen/${purchReqStructure.vendor.vendid}`, {state: {
            purchReqStructure: {...purchReqStructure}
        }});
    }

    function determineIfButtonGotoArticlesMustBeShown(newPurchReqStructure) {
        if (newPurchReqStructure.deliveryAddress.option === '') {
            setShowButtonGotoArticles(false);
            return;
        }

        if (newPurchReqStructure.deliveryAddress.option === 'notAppl' ||
            newPurchReqStructure.deliveryAddress.option === 'dmo') {
            setShowButtonGotoArticles(true);
            return;
        }

        if (newPurchReqStructure.deliveryAddress.option === 'alt') {
            if (newPurchReqStructure.deliveryAddress.address === '') {
                setShowButtonGotoArticles(false);
            } else {
                setShowButtonGotoArticles(true);
            }
        }
    }

    useEffect(() => {
        if (location.state) {
            let newPurchReqStructure = {...location.state.purchReqStructure};
            setDeliveryAddressOption(newPurchReqStructure.deliveryAddress.option);
            setDeliveryAddressText(newPurchReqStructure.deliveryAddress.address);
            setDeliveryAddressInstruction(newPurchReqStructure.deliveryAddress.instruction);

            determineIfButtonGotoArticlesMustBeShown(newPurchReqStructure);

            setPurchReqStructure(newPurchReqStructure);
        }
    }, []);

    return (
        <>
            <Popup
                active={popupActive}
                title={popupTitle}
                text={popupText}
                callback={popupCallback}
                cancelText={t("Nee")}
                confirmText={t("Ja")}
            />

            <h2>Inkoop aanvraag - afleveradres</h2>
            <h4>{requestTypeDescription(purchReqStructure)}</h4>
            <h4>{generalDescription()}</h4>
            <h4>Leverancier: {vendorNameAndAddress()}</h4>
            { purchReqStructure.deviatingVendorAllowed ?
                <h4>Afwijkende leverancier mogelijk</h4>
            :
                <h4>Afwijkende leverancier niet mogelijk</h4>
            }

            <DividerLine />
            <p><strong>Afleveradres</strong></p>

            <RadioGroup
                row
                value={deliveryAddressOption}
                onChange={handleDeliveryAddressOption}
                style={{marginBottom: '40px'}}
            >
                <FormControlLabel value="notAppl" control={<Radio />} label="N.v.t." />
                <FormControlLabel value="dmo" control={<Radio />} label="VDL De Meeuw Oirschot" />
                <FormControlLabel value="alt" control={<Radio />} label="Alternatief" />
            </RadioGroup>
            <div className="row">
            { deliveryAddressOption === 'dmo' || deliveryAddressOption === 'alt' ?
                <>
                    <div style={{ width: '400px'}}>
                        <p><strong>Adres {deliveryAddressOption === 'dmo' ? "" : "(*)"}</strong></p>
                        <TextField
                            label="Afleveradres"
                            disabled = {deliveryAddressOption === 'dmo'}
                            fullWidth
                            multiline
                            rows={5}
                            value = {deliveryAddressText}
                            onChange = {handleDeliveryAddressText}
                        />
                    </div>
                    <div style={{ width: '400px'}}>
                        <p><strong>Aflever-instructies</strong></p>
                        <TextField
                            label="Afleverinstructie"
                            fullWidth
                            multiline
                            rows={5}
                            value={deliveryAddressInstruction}
                            onChange={handleDeliveryAddressInstruction}
                        />
                    </div>
                </>
            :
                null
            }
            </div>
            <div className="bottom">
                <Button
                    variant={showButtonGotoArticles ? 'primary' : 'disabled'}
                    onClick={showButtonGotoArticles ? gotoArticleScreen: null}
                >
                    Ga naar artikelen
                </Button>
            </div>
            <div>
                <Button variant="secondary" icon={false} onClick={gotoMainScreen}>{t("Terug naar kostenplaats / project / leverancier")}</Button>
            </div>
        </>
    );
}
