import React, { useState, useEffect} from 'react';
import SelectIsahArticle from '../../Isah/SelectIsahArticle';
import { PurchReqArticleStructure } from '../Store/PafContext';
import { TextField} from '@mui/material';
import { initPurchReqArticleStructure } from '../Utils/ClearPurchReqStructure';
import { formatIntegerAmount } from '../../../util/formatHelper';
import { formatNumber } from '../../../util/index';

export default function EditIsahArticle({mode, inputRow, vendorId, onUpdatePurchReqArticleStructure}) {

  const [numberAlfa, setNumberAlfa] = useState('');
  const [articleIsSelected, setArticleIsSelected] = useState(false);
  const [purUnitOfAccountDescription, setPurUnitOfAccountDescription] = useState('');
  const [unitDescription, setUnitDescription] = useState('');

  const [purchReqArticleStructure, setPurchReqArticleStructure] = useState(PurchReqArticleStructure);

  const handleArticle = (article) => {
    setArticleIsSelected(false);

    let newPurchReqArticleStructure = {...purchReqArticleStructure};

    if (!article) {
        setArticleIsSelected(false);
        initPurchReqArticleStructure(newPurchReqArticleStructure, 'isah');
    } else {
        setArticleIsSelected(true);

        newPurchReqArticleStructure.unitCheck = determineUnitCheck(article);

        let number = 0;

        if (mode === 'add') {
            if (newPurchReqArticleStructure.unitCheck) {
                number = +article.OrdQty;
            } else {
                number = +article.PurComputQty;
            }
        } else {
            number = inputRow.number;
        }
        setNumberAlfa(number.toString());

        newPurchReqArticleStructure.articleType = 'isah';
        newPurchReqArticleStructure.isahArticle.partCode = article.PartCode.trim();
        if (!article.VendPartCode || article.VendPartCode.trim() === '') {
            newPurchReqArticleStructure.isahArticle.vendPartCode = 'onbekend'
        } else {
            newPurchReqArticleStructure.isahArticle.vendPartCode = article.VendPartCode;
        }
        newPurchReqArticleStructure.number = number;
        newPurchReqArticleStructure.description =  article.Description;
        newPurchReqArticleStructure.isahArticle.unit = article.Unit;
        setUnitDescription(article.Unit);
        newPurchReqArticleStructure.isahArticle.purUnit = article.PurUnit;
        newPurchReqArticleStructure.ordQty = parseFloat(article.OrdQty);

        const purComputQty = parseFloat(article.PurComputQty);
        newPurchReqArticleStructure.isahArticle.purComputQty = purComputQty; // rekenaantal

        const currPurPrice = parseFloat(article.CurrPurPrice);
        newPurchReqArticleStructure.isahArticle.currPurPrice = currPurPrice;

        newPurchReqArticleStructure.totalPrice = (( number / purComputQty) * currPurPrice).toFixed(2);
    }

    setPurchReqArticleStructure(newPurchReqArticleStructure);

    // return articleStructure to component above
    onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
  }

    const handleNumber = (event) => {

        let input = event.target.value.trim();
        const formattedNumber = formatIntegerAmount(input);
        setNumberAlfa(formattedNumber);

        let newNumber = 0;
        if (formattedNumber.length > 0) {
            newNumber = +formattedNumber;
        }

        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.number = newNumber;
        newPurchReqArticleStructure.totalPrice = (( newNumber / purchReqArticleStructure.isahArticle.purComputQty) * purchReqArticleStructure.isahArticle.currPurPrice).toFixed(2);
        setPurchReqArticleStructure(newPurchReqArticleStructure);

        // stuur newPurchReqArticleStructure door naar boven
        onUpdatePurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const determineUnitCheck = (article) => {

        let unit = '';
        if (article.PurUnitOfAccount === 1) {
            unit = 'st';
        } else if (article.PurUnitOfAccount === 2) {
            unit = 'kg';
        } else if (article.PurUnitOfAccount === 3) {
            unit = 'm²';
        } else if (article.PurUnitOfAccount === 4) {
            unit = article.PurUnit.trim();
        }

        setPurUnitOfAccountDescription(unit);
        if (unit === article.Unit.trim().toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        const newPurchReqArticleStructure = {...purchReqArticleStructure};
        initPurchReqArticleStructure(newPurchReqArticleStructure, 'isah');
        setPurchReqArticleStructure(newPurchReqArticleStructure);
    }, []);

    return(
        <>
            <div>
                <SelectIsahArticle
                    vendorId = {vendorId}
                    partCode = {mode === 'add' ? null : inputRow.partCode}
                    onSelectArticle={handleArticle}
                />
            </div>

            { articleIsSelected ?
                <TextField
                    label="Aantal"
                    value= {numberAlfa}
                    onChange={handleNumber}
                    className="TextfieldSmall"
                />
            :
                null
            }

            { articleIsSelected ?
                <div>
                    <p>Artikelnummer: {purchReqArticleStructure.isahArticle.partCode}</p>
                    <p>Artikelnummer leverancier: {purchReqArticleStructure.isahArticle.vendPartCode}</p>
                    <p>Inkoopeenheid: {purchReqArticleStructure.isahArticle.purUnit}</p>
                    {
                        purchReqArticleStructure.unitCheck ?
                            <p>Bestelhoeveelheid: {purchReqArticleStructure.ordQty}</p>
                        :
                            null
                    }
                    <p>Inkoopprijs: {purchReqArticleStructure.isahArticle.currPurPrice } voor {purchReqArticleStructure.isahArticle.purComputQty} {purchReqArticleStructure.isahArticle.purUnit}</p>
                    <p><strong>Totaal stukprijs: {formatNumber(purchReqArticleStructure.totalPrice, 2, 2)}</strong></p>
                    {
                        purchReqArticleStructure.unitCheck ?
                            <p><i>Unit check wordt toegepast</i></p>
                        :
                            <p><i>Unit check wordt niet toegepast</i></p>
                    }
                    <p><i>Reken-aantal eenheid: {purUnitOfAccountDescription}</i></p>
                    <p><i>Bestelhoeveelheid eenheid: {unitDescription}</i></p>
                </div>
            :
                null
            }
        </>
    )
}
