import { useState, useEffect } from 'react';
import { fetchData } from './fetchData';

const useFetchData = (endpoint, method = 'GET', requestData, requestParams) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const result = await fetchData(endpoint, method, requestData, requestParams);
        setData(result);
        setError(null);
        setLoading(false);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, [endpoint, method, requestData, requestParams]);

  return { data, error, loading };
};

export default useFetchData;
