import React, { useState, useEffect} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel} from '@mui/material';
import Button from '../../ui/Button';
import { PurchReqArticleStructure } from '../Store/PafContext';
import EditIsahArticle  from './EditIsahArticle';
import EditManualArticle  from './EditManualArticle';
import { initPurchReqArticleStructure } from '../Utils/ClearPurchReqStructure';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {dateCompare} from '../../../util/dateHelper';

export default function EditPafArticle({
    inputMode,
    inputRow,
    requestType,
    requestTypeDescription,
    articleType,
    inputVendorId,
    currentArticleList,
    onUpdatePafArticleIntoList}
) {
    const [selectedArticleType, setSelectedArticleType] = useState(articleType);
    const [active, setActive] = useState(true);
    const [purchReqArticleStructure, setPurchReqArticleStructure] = useState(PurchReqArticleStructure);
    const [errorText, setErrorText] = useState('');
    const [remark, setRemark] = useState(inputRow? inputRow.remark : '');
    const [deliveryDate, setDeliveryDate] =
        useState(dayjs(inputRow? new Date(inputRow.deliveryDateIso.substring(0, 10)) : new Date()));

    const handleArticleType = (event) => {
        setSelectedArticleType(event.target.value);
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        if (event.target.value === 'isah') {
            initPurchReqArticleStructure(newPurchReqArticleStructure,  'isah');
        } else {
            initPurchReqArticleStructure(newPurchReqArticleStructure,  'manual');
        }
        setPurchReqArticleStructure(newPurchReqArticleStructure);
    };

    const updatePurchReqArticleStructure = (articleStructure) => {
        setErrorText('');
        let newPurchReqArticleStructure = {...articleStructure};
        newPurchReqArticleStructure.remark = remark;
        newPurchReqArticleStructure.deliveryDateIso = deliveryDate.toISOString();
        setPurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const updateRemark = (event) => {
        setRemark(event.target.value);
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.remark = event.target.value;
        setPurchReqArticleStructure(newPurchReqArticleStructure);
    }

    const updateDeliveryDate = (date) => {
        setDeliveryDate(date);
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        newPurchReqArticleStructure.deliveryDateIso = date.toISOString();
        setPurchReqArticleStructure(newPurchReqArticleStructure);
    }

    function handleButton(status) {
        setActive(true);
        if (status === 'cancel') {
            onUpdatePafArticleIntoList(null);
            setActive(false);
            return;
        }

        setErrorText('');

        if (purchReqArticleStructure.articleType === 'isah') {
            if (!purchReqArticleStructure.isahArticle.partCode) {
                setErrorText('ISAH-artikel mag niet leeg zijn');
                return;
            }

            if (inputMode === 'add' && currentArticleList.find(art => art.partCode === purchReqArticleStructure.isahArticle.partCode)) {
                setErrorText('Dit ISAH-artikel is al toegevoegd aan de lijst');
                return;
            }
        }

        if (purchReqArticleStructure.unitCheck && (purchReqArticleStructure.number % purchReqArticleStructure.ordQty !== 0)) {
            // Op dit moment geen unit check voor handmatige artikelen
            setErrorText('Het aantal moet een meervoud zijn van de bestelhoeveelheid');
        return;
        }

        if (purchReqArticleStructure.articleType === 'manual') {
            if (requestType === 'cost') {
                if (purchReqArticleStructure.manualArticle.gbkNumber.trim() === '') {
                    setErrorText('GBK nummer mag niet leeg zijn');
                    return;
                }
            } else {
                if (purchReqArticleStructure.manualArticle.spNumber.trim() === '') {
                    setErrorText('SP nummer mag niet leeg zijn');
                    return;
                }
            }

            if (purchReqArticleStructure.description.trim() === '') {
                setErrorText('De omschrijving mag niet leeg zijn');
                return;
            }

            if (inputMode === 'add' && currentArticleList.find(art => art.description.trim().toUpperCase() === purchReqArticleStructure.description.trim().toUpperCase())) {
                setErrorText('Dit handmatige artikel is al toegevoegd aan de lijst');
                return;
            }

            if (+purchReqArticleStructure.number <= 0) {
                setErrorText('Aantal moet positief zijn');
                return;
            }

            if (+purchReqArticleStructure.ordQty <= 0) {
                setErrorText('Bestelhoeveelheid moet positief zijn');
                return;
            }

            if (+purchReqArticleStructure.manualArticle.pricePerOrdQty <= 0) {
                setErrorText('Prijs per stuk moet positief zijn');
                return;
            }

        }

        if (+purchReqArticleStructure.number < 1) {
            setErrorText('Aantal moet positief zijn');
            return;
        }

        if (dateCompare(new Date(purchReqArticleStructure.deliveryDateIso.substring(0, 10)), new Date()) < 0 ) {
            setErrorText('Leverdatum mag niet in het verleden liggen');
            return;
        }

        setActive(false);
        onUpdatePafArticleIntoList(purchReqArticleStructure);
    }

    useEffect(() => {
        let newPurchReqArticleStructure = {...purchReqArticleStructure};
        initPurchReqArticleStructure(newPurchReqArticleStructure, articleType);
        newPurchReqArticleStructure.remark = inputRow? inputRow.remark : '';
        newPurchReqArticleStructure.deliveryDateIso = inputRow? inputRow.deliveryDateIso : new Date().toISOString();
        setPurchReqArticleStructure(newPurchReqArticleStructure);
    } , []);

    return(
        <>
            { active ?
                <div className="modal-medium-blackout">
                    <div className="modal-medium-container">
                        <div className="modal-medium-title">
                            {
                            inputRow && inputRow.manualArticle === true
                            ?
                                <p>Wijzig handmatig artikel</p>

                            : inputRow && inputRow.manualArticle === false
                            ?
                                <p>Wijzig ISAH artikel</p>

                            : +inputVendorId !== 0
                            ?
                                <p>Voeg artikel toe</p>

                            : +inputVendorId === 0
                            ?
                            <p>Voeg handmatig artikel toe</p>

                            :   null
                            }
                            <p>{requestTypeDescription}</p>
                        </div>
                        <div className="modal-medium-content Form">
                            { inputMode === 'add' && (+inputVendorId !== 0) ?
                                <RadioGroup
                                    row
                                    onChange={handleArticleType}
                                    value = {selectedArticleType}
                                >
                                    <FormControlLabel
                                        value="manual"
                                        control={<Radio />}
                                        label="Handmatig artikel"
                                    />
                                    <FormControlLabel
                                        value="isah"
                                        control={<Radio />}
                                        label="ISAH artikel"
                                    />
                                </RadioGroup>
                            :
                                    null
                            }

                            { selectedArticleType === 'isah' ?
                                <div className="form-container">
                                    <div>
                                        <EditIsahArticle
                                            mode={inputMode}
                                            inputRow={inputRow}
                                            vendorId = {inputVendorId}
                                            onUpdatePurchReqArticleStructure = {updatePurchReqArticleStructure}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Opmerking"
                                            fullWidth
                                            multiline
                                            rows={14}
                                            value={remark}
                                            onChange={updateRemark}
                                        />
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}>
                                            <DatePicker
                                                label="Leverdatum (*)"
                                                format="DD-MM-YYYY"
                                                value={deliveryDate}
                                                onChange={date => updateDeliveryDate(date)}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                </div>
                            :
                                <div className="form-container">
                                    <div>
                                        <EditManualArticle
                                            requestType = {requestType}
                                            inputMode = {inputMode}
                                            inputRow = {inputRow}
                                            onUpdatePurchReqArticleStructure = {updatePurchReqArticleStructure}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Opmerking"
                                            fullWidth
                                            multiline
                                            rows={14}
                                            value = {remark}
                                            onChange = {updateRemark}
                                        />

                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}>
                                            <DatePicker
                                                label="Leverdatum"
                                                format="DD-MM-YYYY"
                                                value={deliveryDate}
                                                onChange={date => updateDeliveryDate(date)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="modal-medium-button-line">
                            <div className="modal-medium-error-line">
                                <p>{errorText}</p>
                            </div>
                            <div>
                                <Button variant="primary" onClick={() => handleButton('cancel')}>
                                    Annuleren
                                </Button>
                            </div>
                            <div>
                                <Button variant="primary" onClick={() => handleButton('confirm')}>
                                    Bevestigen
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            :
                null
            }
        </>
  )
}
