import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { colors } from './colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AppBar from './components/AppBar';
import Drawer from './components/Drawer';

import AuthenticatePage from './pages/AuthenticatePage';

import { routeConfig } from './routeConfig';

import './i18n';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primaryColor
        },
        secondary: {
            main: colors.secondaryColor
        }
    },
});

export default function App() {
    const desktopBreakpoint = 960;

    const [ open, setOpen ] = useState(window.innerWidth > desktopBreakpoint);

    useEffect(() => {
        const handleResize = () => {
            setOpen(window.innerWidth > desktopBreakpoint);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <div className="main-container">
                    <AppBar open={open} setOpen={setOpen}/>
                    <Drawer open={open} setOpen={setOpen}/>
                    <div className="main">
                        <Routes>
                            {routeConfig.map((item) => <Route key={item.path} path={item.path} element={<AuthenticatePage item={item} />} />)}
                        </Routes>
                    </div>
                </div>
            </ThemeProvider>
        </Router>
    );
}
