import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

const dataGridColumns = [
  {
    field: 'id',
    headerName: 'Id',
    width: 100,
  },
  {
    field: 'name',
    headerName: 'Naam',
    width: 170,
    renderCell: (params) => <Link to={`/cto/areatype/${params.row.id}`}>{params.row.name}</Link>
  },
  {
    field: 'color',
    headerName: 'Color',
    width: 170,
  },
];

export default function AreaTypeList() {
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
       const response = await axios.get(process.env.REACT_APP_API_URL + "/demeeuw/areatype/");
       setList(response.data);

      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
        <h1>Flexicom Ruimtes</h1>
        <div className="list">
            <DataGrid
                rows={list}
                columns={dataGridColumns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 30,
                        },
                    },
                    // sorting: {
                    //   sortModel: [{field: Gewicht}]
                    // }
                }}
                pageSizeOptions={[30, 60, 90]}
            />
        </div>
    </>

  )
}
