import React, { useEffect } from 'react';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import gantt from 'dhtmlx-gantt';

const GanttChart = ({data}) => {
  useEffect(() => {

    if (data.length > 0) {
      gantt.init("gantt_here");
      gantt.config.columns = [
        {name:"text", label:"Project", width:"510", align: "left", tree: true },
        {name:"start_date", label:"Start", width: "110", align: "left"},
        {name:"duration", label:"Dagen", width: "50", align: "left" },
        {name:"Aantal units", label:"Units", width: "50", align: "left" }
      ];
      gantt.config.scales = [
            {unit: "year", step: 1, format: "%Y"},
            {unit: "month", step: 1, format: "%M"}
      ];
      gantt.config.scale_height = 54;
      gantt.config.auto_scheduling = false;
      gantt.config.grid_width = 600;

      const parsedData = data.map(item => ({
        ...item,
        start_date: item.start_date ? gantt.date.parseDate(item.start_date, "xml_date") : null,
        end_date: item.end_date ? gantt.date.parseDate(item.end_date, "xml_date") : null,
        text: item.start_date ? item.text : `${item.text} (Geen datum)`,
      }));
      gantt.parse({
        data: parsedData
      });
    }

  }, [data]);

  return (
    <div id="gantt_here" style={{ width: '100%', height: '700px' }}></div>
  );
};

export default GanttChart;
