import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ClothesList({maxValue, list, control, watch, errors}) {
    const { t } = useTranslation();
    const purPartGrpCode = list[0]?.PurPartGrpCode;
    const watchedAmount = watch(`${purPartGrpCode}_amount`); // Watch the value of amount

    const menuItemsAmount = [];
    for (let i = 0; i < maxValue + 1 ; i++) {
        menuItemsAmount.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    return (
        <div className="clothing-item-form">
            <FormControl>
                <InputLabel id={`${purPartGrpCode}-select-label-size`}>{t("Maat")}</InputLabel>
                <Controller
                    name={`${purPartGrpCode}_size`}
                    control={control}
                    rules={{ required: watchedAmount > 0 }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId={`${purPartGrpCode}-select-label-size`}
                            label={ t("Maat") }
                        >
                            {
                            list.map(item => {
                                return (
                                    <MenuItem key={item.PartCode} value={item.PartCode}>{item.Description} ({item.PartCode})</MenuItem>
                                )
                            })
                        }
                    </Select>
                    )}
                />
                    {errors[`${purPartGrpCode}_size`] && <FormHelperText error={true}>Geen maat opgegeven</FormHelperText>}
            </FormControl>

            <FormControl>
                <InputLabel id={`${purPartGrpCode}-select-label-amount`}>{t("Aantal")}</InputLabel>
                <Controller
                    name={`${purPartGrpCode}_amount`}
                    control={ control }
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId={`${purPartGrpCode}-select-label-amount`}
                            label={ t("Aantal") }
                        >
                            {menuItemsAmount}
                        </Select>
                    )}
                />
            </FormControl>
        </div>
    )
}
