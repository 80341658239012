import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectVNumber({orderNumber, onSelectVNumber})   {
    const [dropDownVNumberList, setDropDownVNumberList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let url = process.env.REACT_APP_API_URL + '/isah/productiondossier/getdossiernumberlist';
                const response = await axios.get(url);

                // More V-numbers exists which have an empty description.
                // Create a dropdownlist with all properties unique.
                // If a property is not unique, then <Autocomplete> will yield a ton of console error messages.
                let newList = response.data.map( elem => ({
                    ordnr: elem.ordnr, // unique prop
                    description: elem.description,
                    fullDescription: elem.ordnr + ' (' + elem.description + ')', // unique prop
                }));

                setDropDownVNumberList(newList);
            }
            catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchData();
    }, []);

    const handleVNumber = (event, option) => {
        onSelectVNumber(option);
    }

  return (
    <>
        <p><strong>V-nummer (*)</strong></p>
        <Autocomplete
            disablePortal
            disableClearable
            id="v-number-auto-complete"
            options={dropDownVNumberList}
            getOptionLabel={(option) =>
                (option.fullDescription)
            }
            sx={{ width: 400 }}
            onChange={handleVNumber}
            value={orderNumber || null}
            renderInput={(params) => <TextField {...params} label="V-nummer" />}
        />
    </>
  );
}
