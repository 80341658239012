import React from 'react';
import axios from 'axios';
import Button from '../ui/Button';


export default function Overview() {
    const downloadExcel = () => {
        axios({
            url: process.env.REACT_APP_API_URL + "/demeeuw/scanner/excel",
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cycle_time.xlsx'); // or dynamically set the filename from the response if available
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Clean up
        }).catch((error) => console.error('Download error:', error));
    };


    return (
        <>
            <h1>Download scan overzicht</h1>
            <div>
            <Button onClick={downloadExcel}>Download Excel</Button>
            </div>
        </>
    )
}
