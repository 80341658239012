import React from 'react';
import ChevronIcon from '../../components/icons/ChevronIcon';

export default function Button({children, icon = true, inline = false, variant = "primary", onClick}) {
    return (
        <div onClick={onClick} className={`button button-${variant} ${inline ? "button-inline" : ""} ${icon ? "icon" : ""}`}>
            {children}{icon ? <ChevronIcon/> : null}
        </div>
    );
}
