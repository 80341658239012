export function parseISODate(isoDateStr, onlyDate) {
    const date = new Date(isoDateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    if (onlyDate) {
        return `${day}-${month}-${year}`;
    }

    return `${day}-${month}-${year} ${hours}:${minutes}`;

}


export function formatPrice(price) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
}

export function formatNumber(value, minFractDigits = 0, maxFractDigits = 20) {
    // Convert to float to handle various types of numbers
    const num = parseFloat(value);

    if (isNaN(num)) {
        return value; // Return original value if it's not a valid number
    }

    // Convert number to a string in the standard format
    const strValue = num.toString();

    // If the number is a whole number (i.e., no decimal part)
    if (!strValue.includes('.')) {
        return strValue;
    }

    // Format the number to remove unnecessary trailing zeros after the decimal
    return num.toLocaleString('nl-NL', {
        minimumFractionDigits: minFractDigits,
        maximumFractionDigits: maxFractDigits, // You can adjust this based on your needs
    });
}

export const uc = function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
