module.exports = function (gantt) {
  gantt.skins.meadow = {
    config: {
      grid_width: 350,
      row_height: 27,
      scale_height: 30,
      link_line_width: 2,
      link_arrow_size: 6,
      lightbox_additional_height: 72
    },
    _second_column_width: 95,
    _third_column_width: 80
  };
};