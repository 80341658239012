import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

const dataGridColumns = [
    {
        field: 'id',
        headerName: 'Id',
        width: 100,
    },
    {
        field: 'setting_type',
        headerName: 'Type',
        width: 180,
        renderCell: (params) => <Link to={`/cto/pricesetting/${params.row.id}`}>{params.row.setting_type}</Link>
    },
    {
        field: 'setting_name',
        headerName: 'Naam',
        width: 200,
        renderCell: (params) => <Link to={`/cto/pricesetting/${params.row.id}`}>{params.row.setting_name}</Link>
    },
    {
        field: 'setting_value',
        headerName: 'Waarde',
        width: 140,
    },
    {
        field: 'description',
        headerName: 'Beschrijving',
        width: 300,
    },
];


export default function PriceSettingList() {
    const [list, setList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
           const response = await axios.get(process.env.REACT_APP_API_URL + "/demeeuw/cto/pricesetting");
           setList(response.data);

          } catch (error) {
            console.error("Error:", error);
          }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>Flexicom prijs instellingen</h1>
            <div className="list">
            <DataGrid
                rows={list}
                columns={dataGridColumns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 30,
                    },
                    },
                    // sorting: {
                    //   sortModel: [{field: Gewicht}]
                    // }
                }}
                pageSizeOptions={[30, 60, 90]}
                />
            </div>
        </div>
    )
}
