// Components
import ProductionDossierList from './components/ProductionDossierList';
import AreaTypeList from './components/AreaTypeList';
import AreaType from './components/AreaType';
import ArticleList from './components/ArticleList';
import BillOfMaterial from './components/BillOfMaterial';
import Camera from './components/Camera';
import Configurator from './components/Configurator';

import GrabStock from './components/GrabStock';
import GrabStockEdit from './components/GrabStock/edit';
import Home from './pages/Home';

import QuoteList from './components/QuoteList';
import Quote from './components/Quote';
import Logistics from './components/Logistics';

import OrderForm from './components/OrderForm';
import Planning from './components/Planning';
import TerrainList from './components/TerrainList';
import TransportPlanning from './components/TransportPlanning';

import UserList from './components/UserList';
import NeedsAnalysisForm from './components/NeedsAnalysisForm/NeedsAnalysisForm';
import PriceSettingList from './components/ConfigureToOrder/PriceSettingList';
import PriceSetting from './components/ConfigureToOrder/PriceSetting';
import PurchaseDocumentPage from "./pages/PurchaseDocumentPage";
import PurchaseRequestPage from "./pages/PurchaseRequestPage";
import Vendors from './components/vendor/Vendors';

import ScanPage from "./pages/ScanPage";
import Maintenance from "./components/Maintenance";
import Stock from './components/Stock';

// Icons
import AppsIcon from '@mui/icons-material/Apps';
import ArchiveIcon from './components/icons/ArchiveIcon';
import ArticleIcon from '@mui/icons-material/Article';
import BuildIcon from '@mui/icons-material/Build';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ContactsIcon from '@mui/icons-material/Contacts';
import ExploreIcon from '@mui/icons-material/Explore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PictureInPictureOutlined from '@mui/icons-material/PictureInPictureOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import UnauthenticatedPage from "./pages/AuthenticatePage";

// if roles: []. You only have to be authenticated not authorized
// so if no roles are defined means everyone can access

// Leave icon and title empty if you dont want to show it in the navigation

// when using subitems, the path & component are not used in the main item
// the first path segment needs to be the same for all subitems for the active state to work
export const routeConfig = [
    {
        path: '/',
        component: Home,
        icon: HomeIcon,
        title: 'home',
    },
    {
        path: '/artikelen',
        component: ArticleList,
        icon: ArticleIcon,
        title: 'artikelen',
        roles: ['Articles.Read'],

        // subitems: [
        //     {
        //         path: "/artikelen",
        //         component: ArticleList,
        //         title: "Nieuw artikel 1",
        //         icon: ArticleIcon,
        //         roles: ['Articles.Read'],
        //     },
        //     {
        //         path: "/artikelen/nieuw",
        //         component: ArticleList,
        //         title: "Nieuw artikel 2",
        //         icon: ArticleIcon,
        //         roles: ['Articles.Read'],
        //     }
        // ]
    },
    {
        path: '/camera',
        component: Camera,
        icon: CameraAltIcon,
        title: 'camera',
        roles: ['Camera.Read'],
    },
    {
        path: '/kleding-bestellen',
        component: OrderForm,
        icon: CheckroomIcon,
        title: 'Kleding',
        roles: ["Order.Clothes.Manager", "Order.Clothes.Admin", "Order.Clothes.Personal"],
    },
    {
        path: "/offerte-overzicht",
        component: QuoteList,
        icon: RequestQuoteIcon,
        title: "offertes",
        roles: ['Quotes.Read'],
    },
    { path: "/offerte/:quoteId", component: Quote, roles: ['Quotes.Read'], },
    { path: "/gebouw/:productId", component: Configurator, roles: ['Quotes.Read'], },
    {
        path: "/grabstock",
        component: GrabStock,
        icon: InventoryIcon,
        title: 'grijpvoorraad',
        roles: ['GrabStock.Read'],
    },
    {
        path: '/grabstock/:id',
        component: GrabStockEdit,
        roles: ['GrabStock.Write'],
    },
    {
        path: '/terrein',
        component: TerrainList,
        icon: AppsIcon,
        title: "terrein",
        roles: ['terrain.Read'],
    },
    {
        path: '/transport-planning',
        component: TransportPlanning,
        icon: LocalShippingIcon,
        title: "transport",
        roles: ['transport.Read'],
    },
    {
        path: '/behoefte-inventarisatie',
        component: NeedsAnalysisForm,
        icon: ExploreIcon,
        title: "BIF",
        roles: ['NeedAnalysis.Read'],
    },
    {
        path: '/inkoop-aanvraag',
        component: PurchaseRequestPage,
        icon: ShoppingCartIcon,
        title: "Inkoop aanvraag",
        roles: [],
    },
    {
        path: '/inkooporder',
        component: PurchaseDocumentPage,
        icon: InventoryOutlinedIcon,
        title: "Inkooporder",
        roles: ['PurchaseDocument.Read'],
    },
    { path: '/inkooporder/:orderId', component: PurchaseDocumentPage, roles: ['PurchaseDocument.Read'], },
    {
        path: '/vendors',
        component: Vendors,
        icon: ContactsIcon,
        title: "Leveranciers",
        roles: ['Vendors.Read'],
    },
    { path: '/vendors/artikelen/:vendorId', component: Vendors, roles: ['Vendors.Read'], },
    { path: '/inkoop-aanvraag/afleveradres/:vendorId', component: PurchaseRequestPage, roles: [], },
    { path: "/inkoop-aanvraag/artikelen/:vendorId", component: PurchaseRequestPage, roles: [], },
    { path: "/inkoop-aanvraag/overzicht", component: PurchaseRequestPage, roles: [], },
    {
        path: "/gebruikers",
        component: UserList,
        icon: ManageAccountsIcon,
        title: "gebruikers",
        roles: ['Users.Read'],
    },
    {
        path: "/scanner",
        component: ScanPage,
        icon: QrCodeScannerIcon,
        title: "Cycle time",
        roles: ['Camera.Read'],
    },
    {
        path: "/scanner/overzicht",
        component: ScanPage,
        roles: ['Camera.Read'],
    },
    {
        path: "/onderhoud",
        component: Maintenance,
        icon: HomeRepairServiceIcon,
        title: "Onderhoud",
        // roles: [],
    },
    {
        path: "/stock",
        component: Stock,
        icon: ArchiveIcon,
        title: "vooraad",
        roles: ['Stock.Read'],
    },
    {
        path: "/manco-registratie",
        component: ProductionDossierList,
        icon: BuildIcon,
        title: "manco",
        roles: ['Defect.Read'],
    },
    { path: "/manco-registratie/:salesNumber", component: ProductionDossierList, roles: ['Defect.Read','Defect.Write'], },
    { path: "/manco-registratie/:salesNumber/bom/:dossiercode/:ordnr", component: BillOfMaterial, roles: ['Defect.Read','Defect.Write'], },
    { path: "/manco-registratie/:salesNumber/operations/:dossiercode/:ordnr", component: BillOfMaterial, roles: ['Defect.Read','Defect.Write'], },
    {
        path: "/planning",
        component: Planning,
        icon: ViewTimelineIcon,
        title: "Planning",
        roles: ['MasterPlanning.Read'],
    },
    {
        icon:  StorefrontIcon,
        title: "ConfigureToOrder",
        roles: ['Cto.Admin'],
        subitems: [
            {
                path: "/cto/areatype",
                component: AreaTypeList,
                title: "Ruimtesoorten",
                icon:  PictureInPictureOutlined,
                roles: ['Cto.Admin'],
            },
            {
                path: "/cto/areatype/:id",
                component: AreaType,
                // title: "Area type",
                // icon:  StorefrontIcon,
                roles: ['Cto.Admin'],
            },
            {
                path: "/cto/pricesetting",
                component: PriceSettingList,
                title: "Pricing",
                icon:  SettingsIcon,
                roles: ['Cto.Admin'],
            },
            {
                path: "/cto/pricesetting/:id",
                component: PriceSetting,
                roles: ['Cto.Admin'],
            }
        ]
    },
    { path: "/logistiek", component: Logistics, roles: ['Kbmax.Admin'], },
    // This is a catch-all route for 404 errors:
    { path: '*', component: UnauthenticatedPage, },
];
