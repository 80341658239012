import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { routeConfig } from '../routeConfig';
import { uc } from '../util';

import MuiDrawer from '@mui/material/Drawer';
import NavigationItem from './NavigationItem';
import ArrowCircleLeft from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';

const drawerWidth = 240;

const MaterialDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export default function Drawer({open, setOpen}) {
    const { accounts } = useMsal();
    const account = accounts[0] || false;
    const isAuthenticated = useIsAuthenticated();
    const { t } = useTranslation();

    const hasRole = (account, roles) => roles.some(role => account?.idTokenClaims?.roles?.includes(role));
    const checkRole = (roles) => !roles || roles.length === 0 || hasRole(account, roles);

    const checkAccessNavigationItem = (item) =>  {
        if (item.roles && item.roles?.length === 0 && !isAuthenticated) {
            return false;
        }

        if (item.title && checkRole(item.roles)) {
            return true
        }
        return false;
    }

    const [openSections, setOpenSections] = useState({});

    const toggleSection = (title) => {
        setOpenSections(prev => ({ ...prev, [title]: !prev[title] }));
    };

    const renderNavigationItems = (items) => {
        return items.map((item) => {
            if (checkAccessNavigationItem(item)) {
                const hasSubitems = item.subitems && item.subitems.length > 0;

                return (
                    <div key={item.title}>
                        {hasSubitems && !item.path && (
                            <div
                                className="menu-item"
                                onClick={() => hasSubitems ? toggleSection(item.title) : null}
                            >
                                <item.icon />
                                <div>
                                    <span>{uc(t(item.title))}</span>
                                </div>
                                {hasSubitems && (
                                    <span style={{ marginLeft: 'auto', transform: openSections[item.title] ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
                                        ▶
                                    </span>
                                )}
                            </div>
                        )}
                        {hasSubitems && openSections[item.title] && (
                                item.subitems.map(subitem => {
                                    if (checkAccessNavigationItem(subitem)) {
                                        return (
                                            <NavigationItem
                                                className="subitem"
                                                key={subitem.path}
                                                text={t(subitem.title)}
                                                open={open}
                                                icon={subitem.icon}
                                                path={subitem.path}
                                            />
                                        )
                                    }
                                    return null;
                                })

                        )}
                        {!hasSubitems && item.path && (
                            <NavigationItem
                                key={item.path}
                                text={t(item.title)}
                                open={open}
                                icon={item.icon}
                                path={item.path}
                            />
                        )}
                    </div>
                );
            }
            return null;
        });
    }

    return (
        <MaterialDrawer className={`main-navigation ${open ? 'open' : 'closed'}`} variant="permanent" open={open}>
            <div style={{ flexGrow: 1 }}>
                { renderNavigationItems(routeConfig) }
            </div>
            <div className="menu-item" onClick={() => setOpen(!open)}>

                    { open ? <ArrowCircleLeft/> : <ArrowCircleRight />}

                {open ? <div>Menu invouwen</div> : null}
            </div>
        </MaterialDrawer>
    )
}
