import React from 'react';
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectCostCenter({costCenter, costcenterList, onSelectCostCenter})   {
    const handleCostCenter = (event, option) => {
        onSelectCostCenter(option);
    }

    return (
        <>
            <p><strong>Kostenplaats (*)</strong></p>
            <Autocomplete
                isOptionEqualToValue={(option, value) => option.CostCenterCode === value.CostCenterCode}
                disablePortal
                disableClearable
                id="cost-center-auto-complete"
                options={costcenterList.map( elem => ({
                    CostCenterCode: elem.CostCenterCode.trim(),
                    fullDescription: elem.CostCenterCode.trim() + ' (' + elem.Description + ')'
                }))}
                getOptionLabel={ (option) => option.fullDescription }
                sx={{ width: 300 }}
                onChange={handleCostCenter}
                value={costCenter || null}
                renderInput={(params) => <TextField {...params} label="Kostenplaats" />}
            />
        </>
    );
}
