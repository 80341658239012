import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props
 */
export const ProfileData = (props) => {
  return (
    <div id="profile-div">
      <p>
        <strong>Voornaam: </strong> {props.graphData.givenName}
      </p>
      <p>
        <strong>Achternaam: </strong> {props.graphData.surname}
      </p>
      <p>
        <strong>E-mail: </strong> {props.graphData.userPrincipalName}
      </p>
    </div>
  );
};