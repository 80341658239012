import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '../components/ui/Button';

export default function AreaType(props) {
    const { id } = useParams();
    const [area, setArea] = useState();
    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = async data => {

        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + "/demeeuw/areatype/" + id, data);
        } catch (error) {
            console.error("Error:", error);
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/demeeuw/areatype/" + id);
                setArea(response.data);
                setValue("name", response.data.name || '');
                setValue("color", response.data.color || '#000000');
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
    }, [id])

    return (
        <div>
            <h1>RuimteSoort: {area?.name}</h1>
            <form className="Form">
                <div className="form-item">
                    <input
                        type="text"
                        {...register("name")}

                    />
                    <label>Naam</label>
                </div>
                <div className="form-item">
                    <input
                        type="color"
                        {...register("color")}
                    />
                    <label>Kleur</label>
                </div>
                <div>
                    <Button onClick={handleSubmit(onSubmit)}>Opslaan</Button>
                </div>
            </form>
        </div>
    )
}
