export const initPurchReqArticleStructure = (purchReqArticleStructure, articleType) => {

    purchReqArticleStructure.articleType = articleType;
    purchReqArticleStructure.description = '';
    purchReqArticleStructure.number = 0;
    purchReqArticleStructure.ordQty = 0;
    purchReqArticleStructure.totalPrice = 0;
    
    purchReqArticleStructure.isahArticle.partCode = '';
    purchReqArticleStructure.isahArticle.vendPartCode = '';
    purchReqArticleStructure.isahArticle.purUnit = '';
    purchReqArticleStructure.isahArticle.currPurPrice = '';
    purchReqArticleStructure.isahArticle.purComputQty = '';
    purchReqArticleStructure.isahArticle.unit = '';

    purchReqArticleStructure.manualArticle.pricePerOrdQty = 0;
    purchReqArticleStructure.manualArticle.gbkNumber = '';
    purchReqArticleStructure.manualArticle.gbkNumberDescription = '';
    purchReqArticleStructure.manualArticle.spNumber = '';
    purchReqArticleStructure.manualArticle.spPlanGrpCode = '';

    purchReqArticleStructure.remark = '';
    purchReqArticleStructure.deliveryDate = new Date();
    purchReqArticleStructure.deliveryDateIso = new Date().toISOString();
    purchReqArticleStructure.unitCheck = false;
}

export const clearVendorInPurchReqStructure = (purchReqStructure) => {
    purchReqStructure.deviatingVendorAllowed = null;
    purchReqStructure.vendor.addr = '';
    purchReqStructure.vendor.city = '';
    purchReqStructure.vendor.id = 0;
    purchReqStructure.vendor.name = '';
    purchReqStructure.vendor.phone = '';
    purchReqStructure.vendor.postcode = '';
    purchReqStructure.vendor.vendaddrcode = '';
    purchReqStructure.vendor.vendid = 0;    
    purchReqStructure.vendorIsSelected = false;
    purchReqStructure.vendor.deltermcode = '';
    purchReqStructure.vendor.deltermdescription = '';
}