import React, { useEffect } from 'react';
import Button from '../ui/Button';

export default function Popup({active, title, text, subtext, callback, cancelText, confirmText}) {
    function handleCallback(action) {
        if(typeof callback === "function") {
            callback(action)
        }
    }

    function parseContent() {
        let messageList = text;
        if (typeof(text) == "string") {
            messageList = [text];
        }
        return messageList.map( (item, key) => (
            <div key={key} className="popup-message">
                {item}
            </div>
        ))
    }

	useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleCallback("cancel");
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (event.target.className === 'popup-blackout') {
            handleCallback("cancel");
        }
    };

    return (
        active ? (
            <div className="popup-blackout" onClick={handleOutsideClick}>
                <div className="popup-container">
                    <div className="popup-content">
                        <div className="popup-title">
                            {title}
                        </div>
                        {parseContent()}
                        { subtext ?
                            (<div className="popup-subtext">
                                {subtext}
                            </div>)
                        :
                            null
                        }

                        <div className="popup-footer">
                            <div className="button-row">
                                <div>
                                    { cancelText ?
                                        <Button variant="primary" onClick={() => handleCallback("cancel")}>
                                            {cancelText}
                                        </Button>
                                    :
                                        null
                                    }
                                </div>
                                <div>
                                    { confirmText ?
                                        <Button variant="primary" onClick={() => handleCallback("confirm")}>
                                            {confirmText}
                                        </Button>
                                    :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    :
        null
    );
}
