import React, { useEffect, useState } from 'react';
import { uc } from '../../util'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form"
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useFetchData from '../../util/useFetchData';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import Button from '../ui/Button';

export default function GrabStock() {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const part = state?.part ?? {};
    const [ article, setArticle ] = useState();
    const { data: articleList, articleError, articleLoading } = useFetchData('/isah/article/grabstock');

    const {
        register,
        handleSubmit
     } = useForm()

    const onSubmit = async (data) => {
        data.article_id = article.partcode

        if (id === "new") {
            await createRecord(data)
        } else {
            data.id = part.id
            await updateRecord(data)
        }
        navigate('/grabstock')
    }

    useEffect(() => {
        if (articleList && part.article_id) {
            const matchedArticle = articleList.find(article => article.partcode === part.article_id);
            setArticle(matchedArticle);
        }
    }, [ articleList, part.article_id ]);

    const createRecord = async (item) => {
        axios.post(
            process.env.REACT_APP_API_URL + "/demeeuw/grabstock", item
        ).then(result => {

        }).catch(error => {
            console.error("Error: ", error)
        }).finally(() => {

        })
    }

    const updateRecord = async (item) => {
        axios.put(
            process.env.REACT_APP_API_URL + "/demeeuw/grabstock", item
        ).then(result => {
            //
        }).catch(error => {
            console.error("Error: ", error)
        }).finally(() => {
        })
    }

    return (
        <>
            <h1>{id === "new" ? uc(t('grijpvoorraad toevoegen')) : uc(t('grijpvoorraad aanpassen'))}</h1>
            <div>
                <Button variant="secondary" icon={false} onClick={() => navigate('/grabstock')}>terug naar overzicht</Button>
            </div>
            {articleLoading && <div className="loader">Loading...</div>}
            {articleError && <div>Fout: {articleError.message}</div>}
            {!articleLoading && !articleError && (

            <form onSubmit={handleSubmit(onSubmit)} className="Form grabstock">
                <div>
                    <Autocomplete
                        // disablePortal
                        // id="combo-box-demo"
                        options={articleList}
                        isOptionEqualToValue={(option, value) => option.partcode === value.partcode }
                        getOptionLabel={(option) =>
                            (option.partcode && option.description) ? (option.partcode + " (" + option.description + ")") : "Selecteer een artikel"
                        }
                        renderOption={(props, option) => (
                            <li {...props} key={option.partcode}>
                                {option.partcode + " (" + option.description + ")"}
                            </li>
                        )}
                        onChange={(event, option) => {
                            setArticle(option);
                        }}
                        value={article || null}
                        renderInput={(params) => <TextField {...params} label="Artikel" />}
                    />

                </div>
                <div>
                    <label htmlFor="location">{uc(t('locatie'))}</label>
                    <input id="location" defaultValue={part.location} {...register("location")} />
                </div>

                <div>
                    <label htmlFor="segment">{uc(t('segment'))}</label>
                    <input id="segment" defaultValue={part.segment} {...register("segment")} />
                </div>
                <div>
                    <label htmlFor="amount">{uc(t('aantal in bak'))}</label>
                    <input id="amount" defaultValue={part.amount} {...register("amount")} />
                </div>
                <div>
                    <label htmlFor="unit">{uc(t('eenheid'))}</label>
                    <input disabled id="unit" defaultValue={article?.unit} />
                </div>
                <div>
                    <label htmlFor="container_type">{uc(t('soort bak'))}</label>
                    <input id="container_type" defaultValue={part.container_type} {...register("container_type")} />
                </div>
                <div>
                    <label htmlFor="">{uc(t('aantal bakken'))}</label>
                    <input id="" defaultValue={part.number_of_containers} {...register("number_of_containers")} />
                </div>
                <div>
                    <button type='submit'>Opslaan</button>
                </div>
            </form>
            )}
        </>
    )
}
