import React from 'react';
import { Controller } from "react-hook-form";
import { TextField, FormControl, FormHelperText } from "@mui/material";


export default function TabPanel(props) {
    const { value, index, control, ...other } = props;

    return (
        <div
            className="building-tabpanel"
            role="tabpanel"
            hidden={value !== index}
            id={`building-tabpanel-${index}`}
            aria-labelledby={`building-tab-${index}`}
            {...other}
            style={{ display: value === index ? 'block' : 'none' }} // hide inactive tabs with CSS instead of unmounting
        >
            {value === index && (
                <div>
                    {/* <h2>Gebouw {i}</h2> */}
                    <Controller
                        name={"building_" + index + "_number_of_house"}
                        control={control}
                        defaultValue={null}
                        render={({ field }) =>
                            <TextField
                                id={"outlined-number-" + index}
                                label="Aantal woningen"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                    <Controller
                        name={"building_" + index + "_number_of_housetypes"}
                        control={control}
                        defaultValue={null}
                        render={({ field }) =>
                        <FormControl>
                        <TextField
                            id={"outlined-number-" + index}
                            label="Aantal woningtypes"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormHelperText>Aantal verschillende woningtypes</FormHelperText>
                        </FormControl>
                    }
                    />
                    <Controller
                        name={"building_" + index + "_number_of_common_extern"}
                        control={control}
                        defaultValue={null}
                        render={({ field }) =>
                        <FormControl>
                        <TextField
                            id={"outlined-number-" + index}
                            label="Aantal extern gemeenschappelijk"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormHelperText>Aantal gemeenschappelijke ruimtes extern</FormHelperText>
                        </FormControl>
                    }
                    />
                    {/* Aantal woningen	Aantal
            Aantal woningtypes	Aantal
            Aantal units (woningen)	Aantal
                    */}

                </div>
            )}
        </div>
    );
}
