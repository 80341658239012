import React, { useState } from 'react';
import SelectVendor from '../../vendor/SelectVendor';
import { FormControlLabel} from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function SelectPurchReqVendor({onSelectPurchReqVendor})   {
    const [selectedVendor, setSelectedVendor] = useState(null);

    const [deviatingVendorAllowed, setDeviatingVendorAllowed] = useState(null);
    const [delTermDescription, setDelTermDescription] = useState('');

    const handleVendor = (vendor) => {

        // setErrorText('');
        setDeviatingVendorAllowed(null); // disable both bullets

        if (!vendor) {
          setDelTermDescription('');
          setSelectedVendor(null);
          onSelectPurchReqVendor(null);
          return;
        }

        setSelectedVendor(vendor);
        const delTermDesc = vendor.deltermdescription && vendor.deltermdescription !== '' ? vendor.deltermdescription : 'Franco huis';
        setDelTermDescription(delTermDesc);

        const vendorObject = {
              ...vendor,
              deltermdescription: delTermDesc,
              deviatingVendorAllowed: null
        };
        onSelectPurchReqVendor(vendorObject);
    }

    const handleDeviatingVendor = (event) => {
        setDeviatingVendorAllowed(event.target.value);
        if (selectedVendor) {
            const vendorObject = {
                ...selectedVendor,
                deviatingVendorAllowed: event.target.value === 'yes' ? true : false
            };
            onSelectPurchReqVendor(vendorObject);
        }
    };

    return (
        <div className="row">
            <div>
                <p>
                    <strong>Gewenste leverancier (*)</strong>
                </p>
                <p>Indien onbekend, vul een crediteur-aanvraagformulier in<br/>
                <small><i>O:\Intern\EquseDoks\3.1 Inkoop (INK)\D-INK-008-Crediteurenformulier excel.xlsx</i></small>
                </p>
                <SelectVendor
                    required={true}
                    onSelectVendor={handleVendor}
                />
            </div>
            { selectedVendor ?
                <div>
                    <p><strong>Leverancierconditie</strong></p>
                    <p>{delTermDescription}</p>
                </div>
            :
                null
            }
            { selectedVendor ?
                <div>
                    <p><strong>Afwijkende leverancier mogelijk (*)</strong></p>
                        <RadioGroup
                            row
                            value={deviatingVendorAllowed}
                            onChange={handleDeviatingVendor}
                        >
                        <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                        <FormControlLabel value="no" control={<Radio />} label="Nee" />
                        </RadioGroup>
                </div>
            :
                null
            }
        </div>
    );
}
