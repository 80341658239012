import React, { useState } from 'react';
import useFetchData from '../../util/useFetchData';
import SelectPurchReqVendor from './PurchReqVendor/SelectPurchReqVendor';
import SelectRequestType from './SelectRequestType/SelectRequestType';
import SelectVNumber from './SelectVNumber/SelectVNumber';
import SelectCostCenter from './SelectCostCenter/SelectCostCenter';
import { useTranslation } from 'react-i18next';
import Button from '../ui/Button';
import DividerLine from '../ui/DividerLine';
import { useNavigate } from 'react-router-dom';
import { PurchReqStructure } from './Store/PafContext';
import { clearVendorInPurchReqStructure } from './Utils/ClearPurchReqStructure';

export default function PurchaseRequest() {
    const navigate =  useNavigate();
    const { t } = useTranslation();
    const [ purchReqStructure, setPurchReqStructure ] = useState( PurchReqStructure);
    const [ enableButtonGotoDeliveryAddress, setEnableButtonGotoDeliveryAddress ] = useState(false);
    const [ requestTypeIsCost, setRequestTypeIsCost ] = useState(true);
    const [ vNumberIsSelected, setVNumberIsSelected ] = useState(false);
    const [ costCenterIsSelected, setCostCenterIsSelected ] = useState(false);
    const [ costCenter, setCostCenter ] = useState();
    const [ orderNumber, setOrderNumber ] = useState();

    const handlePurchReqVendor = (vendor) => {
        let newPurchReqStructure = {...purchReqStructure};

        if (vendor) {
            newPurchReqStructure.vendor = {...vendor};
            newPurchReqStructure.deviatingVendorAllowed = vendor.deviatingVendorAllowed;
            setEnableButtonGotoDeliveryAddress(vendor.deviatingVendorAllowed !== null);
        }

        setPurchReqStructure(newPurchReqStructure);
    };

    const handleSelectedRequestType = (requestType) => {
        let newPurchReqStructure = {...purchReqStructure};
        newPurchReqStructure.requestType = requestType;
        clearVendorInPurchReqStructure(newPurchReqStructure);
        setCostCenterIsSelected(false);
        setVNumberIsSelected(false)
        if (requestType === 'cost') {
            setRequestTypeIsCost(true);
            newPurchReqStructure.project.vNumber = null;
        } else {
            setRequestTypeIsCost(false);
            newPurchReqStructure.costCenter.code = null;
        }
        setEnableButtonGotoDeliveryAddress(false);
        setPurchReqStructure(newPurchReqStructure);
    }

    const handleSelectedCostCenter = (costCenterOption) => {
        setCostCenterIsSelected(true);
        setCostCenter(costCenterOption);
        let newPurchReqStructure = {...purchReqStructure};
        newPurchReqStructure.costCenter.code = costCenterOption.costCenterCode;
        newPurchReqStructure.costCenter.fullDescription = costCenterOption.fullDescription ;
        newPurchReqStructure.project.vNumber = null;
        setPurchReqStructure(newPurchReqStructure);
    }

    const { data: costcenterList, error, loading } = useFetchData('/isah/costCenter/getListForPaf/');

    const handleSelectedVNumber = (orderNumberOption) => {
        setVNumberIsSelected(true);
        setOrderNumber(orderNumberOption);
        let newPurchReqStructure = {...purchReqStructure};
        newPurchReqStructure.project.vNumber = orderNumberOption.ordnr;
        newPurchReqStructure.project.fullDescription = orderNumberOption.fullDescription;
        newPurchReqStructure.costCenter.code = null;
        setPurchReqStructure(newPurchReqStructure);
    }

    const gotoDeliveryAddressScreen = () => {
        navigate(`/inkoop-aanvraag/afleveradres/${purchReqStructure.vendor.vendid}`, {state: {
            purchReqStructure: {...purchReqStructure}
        }});
    }

    return (
        <>
            <h1>{t("Inkoop aanvraag")}</h1>
            {loading && <div className="loader">Loading...</div>}
            {error && <div>Fout: {error.message}</div>}
            {!loading && !error && (
            <>
                <div>
                    <SelectRequestType
                        requestType={purchReqStructure.requestType}
                        onSelectRequestType={handleSelectedRequestType}
                    />
                </div>
                <DividerLine />
                <div>
                    { requestTypeIsCost ?
                        <SelectCostCenter costCenter={costCenter} costcenterList={costcenterList} onSelectCostCenter={handleSelectedCostCenter} />
                    :
                        <SelectVNumber orderNumber={orderNumber} onSelectVNumber={handleSelectedVNumber}/>
                    }
                </div>
                <DividerLine />

                { costCenterIsSelected || vNumberIsSelected ?
                    <SelectPurchReqVendor
                        onSelectPurchReqVendor={handlePurchReqVendor}
                    />
                :
                    null
                }

                <div className="bottom">
                    <Button
                        variant={enableButtonGotoDeliveryAddress ? 'primary' : 'disabled'}
                        onClick={enableButtonGotoDeliveryAddress ? gotoDeliveryAddressScreen : null}
                    >
                        {t("Ga naar het afleveradres")}
                    </Button>
                </div>
            </>
            )}
        </>
    )
}
