import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EditPafArticle from './EditPafArticle';
import ArticlesPerVendor from './ArticlesPerVendor';
import { PurchReqStructure } from '../Store/PafContext';
import { formatNumber} from '../../../util/index';
import DividerLine from '../../ui/DividerLine';
import {convertIsoDateToDDMMYYY} from '../../../util/dateHelper';
import { TextField} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Button from '../../ui/Button';
import PafBasicInformation from '../BasicInformation';

export default function AddArticles() {
    const [totalOrderRemark, setTotalOrderRemark] = useState('');
    const [mode, setMode] = useState('');
    const [purchReqStructure, setPurchReqStructure] = useState(PurchReqStructure);

    const navigate =  useNavigate();
    const { t } = useTranslation();
    const [articleIdCounter, setArticleIdCounter] = useState(0);
    const [articleList, setArticleList] = useState([]);

    const [rowData, setRowData] = useState(null);
    const [articleType, setArticleType] = useState('manual');
    const [showModalEditArticle, setShowModalEditArticle] = useState(false);

    const { vendorId } = useParams(); // zie routeConfig voor 'vendorId'.
    const location = useLocation(); // gekoppeld aan 'state' bij het doorgeven van deze link

    const handleTotalOrderRemark = (event) => {
        let input = event.target.value;
        setTotalOrderRemark(input);
        const updatedPurchReqStructure = {...purchReqStructure};
        updatedPurchReqStructure.totalOrderRemark = input;
        setPurchReqStructure(updatedPurchReqStructure);
    }

    const updatePafArticleIntoList = (purchReqArticleStructure) => {
        setShowModalEditArticle(false);

        if (purchReqArticleStructure) {
            if (mode === 'add' && purchReqArticleStructure.articleType === 'isah') {
                addIsahPafArticleToList(purchReqArticleStructure);
            }  else if (mode === 'add' && purchReqArticleStructure.articleType === 'manual') {
                addManualPafArticleToList(purchReqArticleStructure);
            }  else {
                modifyPafArticleList(purchReqArticleStructure)
            }
        }
    }

    const editPafArticle = (row, mode, articleType) => {
        setArticleType(articleType);
        setMode(mode);
        setRowData(row);
        setShowModalEditArticle(true);
    }

    const addManualPafArticleToList = (purchReqArticleStructure) => {
        setArticleList(prevArticleList => {
            const updatedArticleList = [...prevArticleList];

            if (!updatedArticleList.find(art => art.description.trim().toUpperCase() === purchReqArticleStructure.description.trim().toUpperCase())) {
                const priceDescription = formatNumber(+purchReqArticleStructure.manualArticle.pricePerOrdQty, 2, 2) + ` voor ${purchReqArticleStructure.ordQty} st`;
                    updatedArticleList.push({
                    id: articleIdCounter + 1,
                    manualArticle: true,
                    partCode: null,
                    vendPartCode: null,
                    articleNumberDescription: 'HANDMATIG',
                    numberDescription: purchReqArticleStructure.number,
                    number: +purchReqArticleStructure.number,
                    price: +purchReqArticleStructure.manualArticle.pricePerOrdQty,
                    ordQty: +purchReqArticleStructure.ordQty,
                    priceDescription: priceDescription,
                    totalPrice: +purchReqArticleStructure.totalPrice,
                    totalPriceDescription: formatNumber(+purchReqArticleStructure.totalPrice, 2, 2),
                    description: purchReqArticleStructure.description.trim(),
                    remark: purchReqArticleStructure.remark.trim(),
                    deliveryDateIso: purchReqArticleStructure.deliveryDateIso,
                    deliveryDateDescription: convertIsoDateToDDMMYYY(purchReqArticleStructure.deliveryDateIso),
                    gbkNumber: purchReqArticleStructure.manualArticle.gbkNumber,
                    gbkNumberDescription: purchReqArticleStructure.manualArticle.gbkNumberDescription,
                    spNumber: purchReqArticleStructure.manualArticle.spNumber,
                    spNumberDescription: purchReqArticleStructure.manualArticle.spNumberDescription,
                    spPlanGrpCode: purchReqArticleStructure.manualArticle.spPlanGrpCode
                });

                const updatedPurchReqStructure = {...purchReqStructure};
                updatedPurchReqStructure.articles = updatedArticleList;
                updatedPurchReqStructure.totalPriceManualArticles += +purchReqArticleStructure.totalPrice;
                updatedPurchReqStructure.totalGeneralPrice = updatedPurchReqStructure.totalPriceManualArticles + updatedPurchReqStructure.totalPriceIsahArticles;
                setPurchReqStructure(updatedPurchReqStructure);

                setArticleIdCounter( articleIdCounter => { return (articleIdCounter + 1)});
            }
            return updatedArticleList;
        });
    }

  const addIsahPafArticleToList = (purchReqArticleStructure) => {
      setArticleList(prevArticleList => {
        const updatedArticleList = prevArticleList.map(({...elem}) => {return elem});

        if (!updatedArticleList.find(art => art.partCode === purchReqArticleStructure.isahArticle.partCode)) {
          const priceDescription = formatNumber(+purchReqArticleStructure.isahArticle.currPurPrice, 2, 2) + ` voor ${+purchReqArticleStructure.isahArticle.purComputQty} ${purchReqArticleStructure.isahArticle.purUnit}`;
          updatedArticleList.push({
            id: articleIdCounter + 1, // dataGrid vereist een id property
            manualArticle: false,
            partCode: purchReqArticleStructure.isahArticle.partCode.trim(),
            vendPartCode: purchReqArticleStructure.isahArticle.vendPartCode,
            articleNumberDescription: 'ISAH - ' + purchReqArticleStructure.isahArticle.partCode.trim(),
            number: +purchReqArticleStructure.number,
            numberDescription: purchReqArticleStructure.number + ' ' + purchReqArticleStructure.isahArticle.purUnit,
            price: null,
            priceDescription: priceDescription,
            totalPrice: +purchReqArticleStructure.totalPrice,
            totalPriceDescription: formatNumber(+purchReqArticleStructure.totalPrice, 2, 2),
            description: purchReqArticleStructure.description,
            remark: purchReqArticleStructure.remark.trim(),
            deliveryDateIso: purchReqArticleStructure.deliveryDateIso,
            deliveryDateDescription: convertIsoDateToDDMMYYY(purchReqArticleStructure.deliveryDateIso),
            gbkNumber: null,
            gbkNumberDescription: null,
            spNumber: null,
            spNumberDescription: null,
            spPlanGrpCode: null,
          });

          setArticleIdCounter( articleIdCounter => { return (articleIdCounter + 1)});
          const updatedPurchReqStructure = {...purchReqStructure};
          updatedPurchReqStructure.articles = updatedArticleList;
          updatedPurchReqStructure.totalPriceIsahArticles += +purchReqArticleStructure.totalPrice;  // maak aparte variabele van
          updatedPurchReqStructure.totalGeneralPrice = updatedPurchReqStructure.totalPriceManualArticles + updatedPurchReqStructure.totalPriceIsahArticles;
          setPurchReqStructure(updatedPurchReqStructure);
        }

        return updatedArticleList;
      });
  }

  const modifyPafArticleList = (purchReqArticleStructure) => {

    setArticleList(prevArticleList => {

      // let updatedArticleList = [...prevArticleList]; // fout! Dit kopieert alleen indexen en geen objecten.
      const updatedArticleList = prevArticleList.map(({...elem}) => {return elem});
      let selectedArticle = null;
      if (purchReqArticleStructure.articleType === 'isah') {
        selectedArticle = updatedArticleList.find(art => art.partCode === purchReqArticleStructure.isahArticle.partCode);
      } else {
        selectedArticle = updatedArticleList.find(art => art.description.trim() === purchReqArticleStructure.description.trim());
      }

      const oldTotalPrice = selectedArticle.totalPrice;
      const newTotalPrice = +purchReqArticleStructure.totalPrice;

      const newNumber = +purchReqArticleStructure.number;

      selectedArticle.number = newNumber;
      selectedArticle.numberDescription = newNumber;
      selectedArticle.numberDescription += purchReqArticleStructure.articleType === 'isah' ?  ' ' + purchReqArticleStructure.isahArticle.purUnit : '';
      selectedArticle.ordQty = +purchReqArticleStructure.ordQty;
      if (purchReqArticleStructure.articleType === 'manual') {
        selectedArticle.price = +purchReqArticleStructure.manualArticle.pricePerOrdQty;
        selectedArticle.priceDescription = formatNumber(+purchReqArticleStructure.manualArticle.pricePerOrdQty, 2, 2) + ` voor ${purchReqArticleStructure.ordQty} st`;
        selectedArticle.gbkNumber = purchReqArticleStructure.manualArticle.gbkNumber;
        selectedArticle.gbkNumberDescription = purchReqArticleStructure.manualArticle.gbkNumberDescription;
        selectedArticle.spNumber = purchReqArticleStructure.manualArticle.spNumber;
        selectedArticle.spNumberDescription = purchReqArticleStructure.manualArticle.spNumberDescription;
        selectedArticle.spPlanGrpCode = purchReqArticleStructure.manualArticle.spPlanGrpCode;
      }
      selectedArticle.remark = purchReqArticleStructure.remark.trim();
      selectedArticle.deliveryDateIso = purchReqArticleStructure.deliveryDateIso;
      selectedArticle.deliveryDateDescription = convertIsoDateToDDMMYYY(selectedArticle.deliveryDateIso);
      let updatedPurchReqStructure = {...purchReqStructure};

      if (purchReqArticleStructure.articleType === 'isah') {
        updatedPurchReqStructure.totalPriceIsahArticles +=  newTotalPrice - oldTotalPrice;
      } else {
        updatedPurchReqStructure.totalPriceManualArticles += newTotalPrice - oldTotalPrice;
      }
      updatedPurchReqStructure.totalGeneralPrice = updatedPurchReqStructure.totalPriceManualArticles + updatedPurchReqStructure.totalPriceIsahArticles;

      selectedArticle.totalPrice = newTotalPrice;
      selectedArticle.totalPriceDescription = formatNumber(newTotalPrice, 2, 2);

      updatedPurchReqStructure.articles = updatedArticleList;
      setPurchReqStructure(updatedPurchReqStructure);
      return updatedArticleList;
      }
    );
  };

    const handleDeleteButton = (id) => {
        let article = purchReqStructure.articles.find(art => art.id === id);
        let newPurchReqStructure = {...purchReqStructure};
        if (article.manualArticle === true) {
            newPurchReqStructure.totalPriceManualArticles -= article.totalPrice;
        } else {
            newPurchReqStructure.totalPriceIsahArticles -= article.totalPrice;
        }
        newPurchReqStructure.totalGeneralPrice = newPurchReqStructure.totalPriceIsahArticles + newPurchReqStructure.totalPriceManualArticles;
        setArticleList(prevArticleList => {
            let updatedArticleList = [...prevArticleList];
            updatedArticleList = updatedArticleList.filter(art => art.id !== id);
            newPurchReqStructure.articles = updatedArticleList;
            setPurchReqStructure(newPurchReqStructure);
            return updatedArticleList;
        });
    }

    const handleUpdateButton = (id) => {
        const row = articleList.find(art => art.id === id);
        if (row.manualArticle === true) {
            editPafArticle(row, 'update', 'manual')
        } else {
            editPafArticle(row, 'update', 'isah');
        }
    }

    const requestTypeDescription = (purchReqStructure) => {
    if (purchReqStructure.requestType === 'cost') {
        return 'Type verzoek: Kosten';
    } else if (purchReqStructure.requestType === 'project') {
        return 'Type verzoek: Project';
    } else if (purchReqStructure.requestType === 'investment-application') {
        return 'Type verzoek: Investeringsaanvraag (GBK0291)';
    }
    }

    const gotoOverviewAndProcess = () => {
        navigate('/inkoop-aanvraag/overzicht', {state: {
        ...location.state,
        purchReqStructure: {...purchReqStructure}
        }});
    }

    const gotoDeliveryAddress = () => {
        navigate('/inkoop-aanvraag/afleveradres/' + {vendorId}, {state: {
            ...location.state,
            purchReqStructure: {...purchReqStructure}
        }});
    }

    useEffect(() => {
        if (location.state) {
            let newPurchReqStructure = {...location.state.purchReqStructure};

            setTotalOrderRemark(newPurchReqStructure.totalOrderRemark);
            setArticleList(newPurchReqStructure.articles);
            setArticleIdCounter(newPurchReqStructure.articles.length);
            setPurchReqStructure(newPurchReqStructure);
        }
    }, []);

    return (
        <>
        <h2>Inkoop aanvraag - artikelen</h2>

        <div style={{ display: 'flex'}}>
            <div style={{ margin: '0px 10px 0px 0px'}}>
                <PafBasicInformation
                    purchReqStructure={location.state.purchReqStructure}
                />

                <TextField
                    label="Opmerking order"
                    value= {totalOrderRemark}
                    onChange={handleTotalOrderRemark}
                    className="TextfieldExtraLarge"
                />
            </div>

            <div style={{margin: '0px 10px 0px 300px'}}>
            {
                purchReqStructure.requestType === 'cost' ?
                <p><strong>Totaalprijs GBK-artikelen: {formatNumber(purchReqStructure.totalPriceManualArticles, 2, 2)}</strong></p>
                :
                <p><strong>Totaalprijs SP-artikelen: {formatNumber(purchReqStructure.totalPriceManualArticles, 2, 2)}</strong></p>
            }
                <p><strong>Totaalprijs ISAH-artikelen: {formatNumber(purchReqStructure.totalPriceIsahArticles, 2, 2)}</strong></p>

                <DividerLine />
                <p><strong>Totaalprijs alle artikelen: {formatNumber(purchReqStructure.totalGeneralPrice, 2, 2)}</strong></p>

            <div>
                <Button onClick={() => editPafArticle(null, 'add', 'manual')}>Voeg artikel toe</Button>
            </div>

            </div>
        </div>

        <DividerLine />
        { showModalEditArticle ?
            <div>
                <EditPafArticle
                    inputMode = {mode}
                    inputRow = {rowData}
                    requestType = {purchReqStructure.requestType}
                    requestTypeDescription = {requestTypeDescription(purchReqStructure)}
                    articleType = {articleType}
                    inputVendorId = {vendorId}
                    currentArticleList = {articleList}
                    onUpdatePafArticleIntoList = {updatePafArticleIntoList}
                />
            </div>
            :
            null
        }
            <div className="Form">
                <ArticlesPerVendor
                    articleList={articleList}
                    requestType = {purchReqStructure.requestType}
                    onDelete={handleDeleteButton}
                    onUpdate={handleUpdateButton}
                />
                <div>
                    <Button
                        variant={articleList.length > 0 ? 'primary' : 'disabled'}
                        onClick={articleList.length > 0 ? gotoOverviewAndProcess: null}
                    >
                        Naar totaaloverzicht en verwerken
                    </Button>
                </div>
                <div>
                    <Button variant="secondary" icon={false} onClick={gotoDeliveryAddress}>{t("Terug naar aflever-adres scherm")}</Button>
                </div>
            </div>
        </>
    );
}
