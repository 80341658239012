import useFetchData from '../util/useFetchData';
import { DataGridPro, gridNumberComparator } from '@mui/x-data-grid-pro';

const dataGridColumns = [
  {
    field: 'partCode',
    headerName: 'Artikel nummer',
    width: 120,
  },
  {
    field: 'artGrp',
    headerName: 'Artikel groep',
    width: 210,
  },
  {
    field: 'description',
    headerName: 'Omschrijving',
    width: 160,
  },
  {
    field: 'weight',
    headerName: 'Gewicht',
    width: 170,
    // align: 'right',
    sortComparator: gridNumberComparator,
    valueGetter: (params) => params.row.weight.toLocaleString('en-US'),
  },
  {
    field: 'costPrice',
    label: 'Kostprijs',
    width: 170,
    sortable: false,
    valueGetter: (params) => params.row.costPrice.toFixed(2).toString().replace('.', ',')
  },
];

const ArticleList = () => {
  const { data: list, error, loading } = useFetchData('/isah/articlelist');

  return (
    <div>
      <h1>Artikelen</h1>
      {loading && <div className="loader">Loading...</div>}
      {error && <div>Fout: {error.message}</div>}
      {!loading && !error && (
        <div style={{ height: 800, width: '100%' }}>
          <DataGridPro
            getRowId={(row) => row.id}
            rows={list}
            columns={dataGridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 30,
                },
              },
            }}
            pageSizeOptions={[30, 60, 90]}
          />
        </div>
      )}
    </div>
  );
};

export default ArticleList;
