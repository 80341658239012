export const PurchReqStructure = {
    deviatingVendorAllowed: null,
    vendor:  {
        addr: '',
        city: '',
        id: 0,
        name: '',
        phone: '',
        postcode: '',
        vendaddrcode: '',
        vendid: 0,
        deltermcode: '',
        deltermdescription: ''
    },
    deliveryAddress: {
        option: '',
        address: '',
        instruction: ''
    },
    requestType: 'cost',
    costCenter: {
        code: '',
        fullDescription: ''
    },
    project: {
        vNumber: '',
        fullDescription: '',
    },
    articles: [],
    totalPriceManualArticles: 0,
    totalPriceIsahArticles: 0,
    totalGeneralPrice: 0,
    totalOrderRemark: '',
    totalText: '',
    approvalFile: null,
    approvalFileObject: {
        name: '',
        size: 0
    },
    quoteFiles: [],
    quoteFileObjects: []
};

 export const PurchReqArticleStructure = {
    articleType: '',
    description: '',
    number: 0,
    ordQty: 0,
    totalPrice: 0,
    mandatoryFieldsAreFilled: false,
    isahArticle: {
        partCode: '',
        vendPartCode: '',
        purUnit: '',
        currPurPrice: '',
        purComputQty: '',
        unit: '',
    },
    manualArticle: {
        pricePerOrdQty: 0,
        gbkNumber: '',
        gbkNumberDescription: '',
        spNumber: '',
        spNumberDescription: '',
        spPlanGrpCode: '',
    },
    remark: '',
    deliveryDate: new Date(),
    deliveryDateIso: new Date().toISOString(),
    unitCheck: false
 }
