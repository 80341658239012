import React from 'react';
import { Link } from "react-router-dom";


export default function KBMaxData() {
    return (
        <div>
            <Link to={"/kbmax/areatype"}>AreaType List</Link>
        </div>
    )
}