import React, { useState, useEffect } from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const operationList = [
    { code: 'A', description: 'Technisch paneeltje vervangen', hours: 0.75 },
    { code: 'B', description: 'Binnenplaat vervangen', hours: 0.5 },
    { code: 'C', description: 'Buitenplaat vervangen', hours: 0.25 },
    { code: 'D', description: 'PVC vloerbedekking vervangen', hours: 1.5 },
    { code: 'E', description: 'Dak repareren', hours: 1 },
    { code: 'F', description: 'Paneel vervangen', hours: 1.5 },
    { code: 'G', description: 'Akoestische platen aanbrengen', hours: 0.75 },
    { code: 'H', description: 'Trap repareren', hours: 16 },
    { code: 'I', description: 'Trap versteviging 5KN onderkant', hours: 16 },
    { code: 'J', description: 'Trap versteviging 5KN bovenkant', hours: 8 },
    { code: 'K', description: 'Binendeur vervangen (brandwerend)', hours: 4 },
    { code: 'L', description: 'Dubbele buitendeur aanpassen/vervangen', hours: 2 },
    { code: 'M', description: 'Douchevloer repareren', hours: 4 },
];

const moduleOperations = {
    'FX-01': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-02/FX-02-A': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-03/FX-03-A/FX-03-ZK': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    'FX-04/FX-04-A/FX-04-ZK': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    'FX-05/FX-05-A/FX-05-ZK': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    'FX-06': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-07': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-08': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-10': ['A', 'B', 'C', 'D', 'E', 'F'],
    // 'FX-11': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-12': ['A', 'B', 'C', 'D', 'E', 'F', 'H', 'I'],
    'FX-13': ['A', 'B', 'C', 'D', 'E', 'F', 'J', 'K'],
    'FX-14': ['A', 'B', 'C', 'D', 'E', 'F', 'L'],
    'FX-15': ['B', 'C', 'D', 'E', 'F'],
    'FX-16': ['A', 'B', 'C', 'D', 'E', 'M'],
};

const moduleList = Object.keys(moduleOperations);

const catThresholds = [
    { cat: 0, threshold: 0 },
    { cat: 1, threshold: 0.25 },
    { cat: 2, threshold: 2 },
    { cat: 3, threshold: 4 },
    { cat: 4, threshold: 8 },
    { cat: 5, threshold: 16 }
];

export default function Maintenance() {
    const [selectedModule, setSelectedModule] = useState('');
    const [count, setCount] = useState({});

    useEffect(() => {
        setCount({});
    }, [selectedModule]);

    const calculateTotalHours = () => {
        // Calculate total hours based on the count of each operations
        return Object.keys(count).reduce((total, opCode) => {
            const operation = operationList.find(op => op.code === opCode);
            const currentCount = count[opCode] || 0;
            return total + operation.hours * currentCount;
        }, 0);
    };


    const handleCount = (opCode, change) => {
        setCount(prevCounts => ({
            ...prevCounts,
            [opCode]: (prevCounts[opCode] || 0) + change
        }));
    }

    const getCatNumber = (hours) => {
        for (let i = catThresholds.length - 1; i >= 0; i--) {
            if (hours >= catThresholds[i].threshold) {
                return catThresholds[i].cat;
            }
        }
        return 0;
    };

    const handleReset = () => {
        setCount({});
    };

    const totalHours = calculateTotalHours();
    const catNumber = getCatNumber(totalHours);

    return (
        <div>
            <h1>Onderhoud</h1>
            <p>Selecteer een module en de bijbehorende werkzaamheden om de onderhoudscategorie te berekenen.</p>
            <div id='service-container'>
                <div className='row'>
                <select className='custom-select' onChange={(e) => setSelectedModule(e.target.value)} value={selectedModule}>
                    <option value="">Selecteer module</option>
                    {moduleList.map((module) => (
                        <option key={module} value={module}>{module}</option>
                    ))}
                </select>
                <button onClick={handleReset} className='reset-button'>
                    <RestartAltIcon />
                </button>
                </div>
                <div className='checkbox-container'>
                {selectedModule && moduleOperations[selectedModule].map((opCode) => {
                    const operation = operationList.find(op => op.code === opCode);
                    return (
                        <div key={operation.code}>
                            <div className="manco-count-input" style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={() => handleCount(operation.code, -1)}>-</button>
                                <input
                                type="text"
                                value={count[operation.code] || 0}
                                onChange={(e) => {
                                    // nothing happens
                                }}
                                style={{ width: '50px', textAlign: 'center' }}
                                />
                                <button onClick={() => handleCount(operation.code, 1)}>+</button>
                            </div>
                            <label htmlFor={operation.code}>{operation.description} ({operation.hours} uur)</label>
                        </div>
                    );
                })}
                </div>

                {selectedModule && (
                    <div>
                        <h3>CAT Nummer: {catNumber}</h3>
                        <p>Totale uren: {totalHours}</p>
                    </div>
                )}
            </div>
        </div>
    )
}
