import React, { useState } from "react";
import { DataGridPro, nlNL } from '@mui/x-data-grid-pro';
import { useTranslation } from "react-i18next";
import { uc } from '../../util'
import ExpandMoreToggle from "../ui/ExpandMoreToggle";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DocumentList = ({list}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleCopy = (item) => {
        navigator.clipboard.writeText(item).then(() => {
            // maybo a small popup indicating success
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    };

    const documentListColumns = [
        {
            field: 'Doc.nr.',
            headerName: uc(t('doc.nr.')),
            flex: 1
        },
        {
            field: 'Bestand omschrijving',
            headerName: uc(t('bestand omschrijving')),
            flex: 2
        },

        {
            field: 'Bestandsnaam',
            headerName: uc(t('bestandsnaam')),
            flex: 4,
            renderCell: (params) => (
                <div style={{position: 'relative', marginLeft: '30px'}}>
                    <ContentCopyIcon style={{cursor: 'pointer', position: 'absolute', top: '-3px', left: '-30px'}} onClick={() => handleCopy(params.row.Bestandsnaam)}/>
                    { params.row.Bestandsnaam }
                </div>
            )
        },
    ]

    return (
        <>
            <div className="row" onClick={handleToggle} style={{cursor: "pointer", alignItems: 'center'}}>
                <h4>Documenten</h4>
                <ExpandMoreToggle isOpen={isOpen}/>
            </div>

            {isOpen && (
                <div style={{ transition: 'max-height 0.3s', maxHeight: isOpen ? '1000px' : '0', overflow: 'hidden' }}>
                    <DataGridPro style={{backgroundColor: 'white'}}
                        rows={list}
                        rowHeight={40}
                        disableAutosize
                        columns={documentListColumns}
                        getRowId={(row) => row['Doc.nr.']}
                        localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                        hideFooter={true}
                    />
                </div>
            )}
        </>
    )
}

export default DocumentList;
