import React from 'react';
import KBMaxData from '../components/KBMaxData';
import AreaTypeList from '../components/AreaTypeList';
import AreaType from '../components/AreaType';
import { useLocation } from "react-router-dom";


export default function PartcodeList() {
  const location = useLocation();

  function content() {
    if (location.pathname === "/kbmax/areatype") {
      return (
        <>
          <h1>KBMax AreaList</h1>
          <AreaTypeList />
        </>
      )
    }

    if (location.pathname.startsWith("/kbmax/areatype/")) {
      return <AreaType />
    }

    return (
      <>
        <h1>KBMax Settings</h1>
        <KBMaxData />
      </>
    )
  }

  return content();
}
