import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {formatNumber, parseISODate} from '../util/index'

// Artikel, Artikelomschrijving, magazijn locatie, voorraad, eenheid, regel, artikeltype, mutatiedatum voorraadstatus
const dataGridColumns = [
  {
    field: 'PartCode',
    headerName: 'Artikel',
    width: 80,
  },
  {
    field: 'PartDescription',
    headerName: 'Artikelomschrijving',
    width: 260,
  },
  {
    field: 'LocationCode',
    headerName: 'Magazijn locatie',
    width: 130,
  },
  {
    field: 'Invt',
    headerName: 'Vooraad',
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => formatNumber(value),
    width: 80,
  },
  {
    field: 'PartUnit',
    headerName: 'Eenheid',
    width: 80,
  },
  {
    field: 'InvtLineNr',
    headerName: 'Regel',
    width: 80,
  },
  {
    field: 'ArticleType',
    headerName: 'Artikeltype',
    width: 100,
  },
  {
    field: 'LastUpdatedOn',
    headerName: 'Mutatiedatum',
    width: 160,
    valueGetter: (value, row) => parseISODate(row.LastUpdatedOn.date)
  },
  {
    field: 'InventoryStatusCode',
    headerName: 'Voorraadstatus',
    width: 120,
  },
]

export default function Stock() {
    const { t } = useTranslation();
    const [list, setList] = useState();
    const [list2, setList2] = useState();


    useEffect(() => {
        const fetchData = async () => {
            try {
              const stockResponse = await axios.get(process.env.REACT_APP_API_URL + "/isah/inventorylines" );
              const stock2Response = await axios.get(process.env.REACT_APP_API_URL + "/isah/inventorylines/negative" );
              setList(stockResponse.data);
              setList2(stock2Response.data);

            } catch (error) {
              console.error("Error:", error);
            }
          };

          fetchData();
          const intervalId = setInterval(fetchData, 30 * 60 * 1000);

          // Cleanup: clear the interval when the component is unmounted
          return () => clearInterval(intervalId);
    }, []);

    return <div>
        <h2 className="small-header">{t("In transit")}</h2>
        {
        list && list2 && list.length >= 0 ?
          <div className="list">
              <DataGrid
                rows={list}
                getRowId={(row) => row.id}
                columns={dataGridColumns}
                localeText={{ noRowsLabel: t('Geen rijen gevonden') }}
                hideFooterPagination={true}
                hideFooter={true}
                rowHeight={25}
                disableRowSelectionOnClick
              />

              <h2 className="small-header">{t("Negatieve voorraad logistiek")}</h2>
              <DataGrid
                rows={list2}
                getRowId={(row) => row.id}
                columns={dataGridColumns}
                localeText={{ noRowsLabel: t('Geen rijen gevonden') }}
                hideFooterPagination={true}
                hideFooter={true}
                rowHeight={25}
                disableRowSelectionOnClick
              />
            </div>
          : <div className="loader">Loading...</div>
      }
    </div>
}
