import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectVendor({required, onSelectVendor})   {
    const [dropDownVendorList, setDropDownVendorList] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseVendors = await axios.get(process.env.REACT_APP_API_URL + '/isah/vendor/getList');
                const vendorList = responseVendors.data.filter(vend => vend.name !== 'DEFAULT');
                setDropDownVendorList(vendorList);
            }
            catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchData();
    }, []);

    const handleVendor = (vendor) => {

        if (!vendor) {
          setSelectedVendor(null);
          onSelectVendor(null);
          return;
        }
        setSelectedVendor(vendor);
        onSelectVendor(vendor);
    }

    return (
        <>
            <Autocomplete
                isOptionEqualToValue={(option, value) => option.vendid === value.vendid}
                disablePortal
                disableClearable = {required === true}
                id="vendor-auto-complete"
                options={dropDownVendorList}
                getOptionLabel={(option) =>
                    (option.vendid && option.name) ? option.vendid + ' - ' + option.name + ' - ' + option.city: ''
                }
                sx={{ width: 300 }}
                onChange={(event, option) => {
                    handleVendor(option);
                }}
                value={selectedVendor || null}
                renderInput={(params) => <TextField {...params} label="Leverancier" />}
            />
        </>
    );
}
