import React from 'react';

import { styled } from '@mui/material/styles';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { routeConfig } from '../routeConfig';

import List from '@mui/material/List';
import MuiDrawer from '@mui/material/Drawer';
import NavigationItem from './NavigationItem';
import ArrowCircleLeft from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';

const drawerWidth = 240;

const MaterialDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export default function Drawer({open, setOpen}) {
    const { accounts } = useMsal();
    const account = accounts[0] || false;
    const isAuthenticated = useIsAuthenticated();
    const { t } = useTranslation();

    const hasRole = (account, roles) => roles.some(role => account?.idTokenClaims?.roles?.includes(role));
    const checkRole = (roles) => !roles || roles.length === 0 || hasRole(account, roles);

    const checkAccessNavigationItem = (item) =>  {
        if (item.roles && item.roles?.length === 0 && !isAuthenticated) {
            return false;
        }

        if (item.title && checkRole(item.roles)) {
            return true
        }
        return false;
    }

    return (
        <MaterialDrawer className={`main-navigation ${open ? 'open' : 'closed'}`} variant="permanent" open={open}>
            <List style={{ flexGrow: 1 }}>
                {routeConfig.map((item) => {
                    if (checkAccessNavigationItem(item)) {
                        return (
                            <NavigationItem
                                key={item.path}
                                text={t(item.title)}
                                open={open}
                                icon={item.icon}
                                path={item.path}
                            />
                        );
                    }
                    return null;
                })}
            </List>
            {
                open ?
                    <div className="menu-item" onClick={() => setOpen(false)}>
                        <div><ArrowCircleLeft /></div>
                        <div>Menu invouwen</div>
                    </div>
                :
                    <div className="menu-item" onClick={() => setOpen(true)}>
                        <div><ArrowCircleRight /></div>
                    </div>
            }
        </MaterialDrawer>
    )
}
