import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import NotFound from "./NotFound";

function useGoogleAnalytics(account) {
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const userId = isAuthenticated ? account?.username : 'not_logged_in';

        window.gtag('config', 'G-3H3MHQ4GYT', {
            page_path: location.pathname,
            user_id: userId
        });

    }, [location, account]);
}

export default function AuthenticatePage(props) {
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const account = accounts[0] || false;
    const { item } = props;
    useGoogleAnalytics(account);

    const checkRole = (roles) =>!roles || roles.length === 0 || hasRole(account, roles);
    const hasRole = (account, roles) => roles.some(role => account?.idTokenClaims?.roles?.includes(role));

    if (isAuthenticated && item?.path === "*") {
        return <div className="main-content"><NotFound/></div>
    }

    if (isAuthenticated && !checkRole(item?.roles)) {
        return <div className="main-content">
            <h5>
                <center>
                    {t("U bent niet geauthoriseerd om deze pagina te zien.")}
                </center>
            </h5>
        </div>
    }

    if (isAuthenticated && checkRole(item?.roles)) {
        return <div className="main-content"><item.component title={item.title} /></div>;
    }

    if (!isAuthenticated && item?.roles === undefined) {
        return <div className="main-content"><item.component title={item.title} /></div>;
    }

    return (
        <div className="main-content">
            <h5>
                <center>
                    {t("U dient in te loggen om deze pagina te zien.")}
                </center>
            </h5>
        </div>
    );
}
