import React, { useEffect, useState } from 'react';
import { uc } from '../../util'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import useFetchData from '../../util/useFetchData';
import { DataGridPro, nlNL } from '@mui/x-data-grid-pro';
import Button from '../../components/ui/Button';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import Popup from '../ui/Popup';

export default function GrabStock() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const account = accounts[0];
    const [popupActive, setPopupActive] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [popupTitle, setPopupTitle] = useState("");
    const [selectedId, setSelectedId] = useState();

    const { data: fetchedList, error, loading } = useFetchData('/demeeuw/grabstock');
    const [list, setList] = useState([]);

    useEffect(() => {
        if (fetchedList) {
            setList(fetchedList);
        }
    }, [fetchedList]);

    const hasRole = (account, roles) => roles.some(role => account?.idTokenClaims?.roles?.includes(role));
    const writeRights = hasRole(account, ["GrabStock.Write"]);

    const handleEditClick = (row) => {
        navigate(`/grabstock/${row.id}`, { state: { part: row } });
    };

    const handleDelete = (id) => {
        setPopupTitle(t("Verwijderen"));
        setPopupText(t("Weet u het zeker?"));
        setPopupActive(true);
        setSelectedId(id)
    }

    const popupCallback = (type) => {
        setPopupText("");
        setPopupActive(false);
        const id = selectedId;
        setSelectedId(null);
        if (type === 'confirm' && selectedId) {
            axios.delete(
                process.env.REACT_APP_API_URL + "/demeeuw/grabstock/" + selectedId,
            ).then(response => {
                if (response.status === 200) {
                    setList(prevList => prevList.filter(item => item.id !== id));
                }
            }).catch(error => {
                console.error("Error: ", error)
            }).finally(() => {

            })
        }

    }

    const dataGridColumns = [
        {
            field: 'article_id',
            headerName: 'Artikelnr',
            width: 120,
        },
        {
            field: 'description',
            headerName: 'Omschrijving',
            width: 160,
        },
        {
            field: 'locationSegment',
            headerName: 'Segment',
            width: 160,
            valueGetter: (params) => {
                const location = params.row.location || '';
                const segment = params.row.segment || '';
                return `${location} ${segment}`.trim();
            }
        },
        {
            field: 'amount',
            headerName: 'Aantal in bak',
            width: 160,
        },
        {
            field: 'unit',
            headerName: 'Eenheid',
            width: 160,
        },
        {
            field: 'container_type',
            headerName: 'Soort bak',
            width: 160,
        },
        {
            field: 'number_of_containers',
            headerName: 'Aantal bakken',
            width: 160,
        },
    ];

    if (writeRights) {
      dataGridColumns.unshift({
          field: 'actions',
          headerName: '',
          width: 100,
          renderCell: (params) => (
              <>
                  <IconButton onClick={() => handleEditClick(params.row)} aria-label="edit">
                      <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(params.row.id)} aria-label="delete">
                      <DeleteIcon />
                  </IconButton>
              </>
          ),
          sortable: false,
          filterable: false,
      });
    }

    return (
        <>
            <h1>{uc(t('grijpvoorraad'))}</h1>

            {writeRights &&
              <div>
                <Button onClick={() => navigate('/grabstock/new')}>Voeg grijpvoorraad toe <span>+</span></Button>
              </div>
            }
            {loading && <div className="loader">Loading...</div>}
            {error && <div>Fout: {error.message}</div>}
            {!loading && !error && (
              <div style={{ height: 800, width: '100%' }}>
                <DataGridPro
                  getRowId={(row) => row.id}
                  localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                  unstable_headerFilters
                  disableColumnFilter
                  pagination
                  pageSizeOptions={[30, 60, 90]}
                  paginationMode="client"
                  rows={list}
                  columns={dataGridColumns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 30,
                      },
                    },
                  }}

                />
              </div>
            )}
            <Popup
                active={popupActive}
                title={popupTitle}
                text={popupText}
                callback={popupCallback}
                confirmText={t("ja")}
                cancelText={t("nee")}
            />
        </>
    )
}
