import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Link, useLocation } from 'react-router-dom';
import NavigationButton from './NavigationButton';


export default function NavigationItem({ path, text, open, icon: IconComponent }) {
    const location = useLocation();

    const locationSegments = location.pathname.split("/");
    const pathSegments = path.split("/");

    return (
        <ListItem
            className={pathSegments[1] === locationSegments[1] ? "active" : "inactive" }
            key={text}
            component={Link}
            to={path}
            disablePadding
        >
            <NavigationButton text={text} open={open}><IconComponent/></NavigationButton>
        </ListItem>
    )
}
