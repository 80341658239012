import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useGridApiRef } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { nlNL } from '@mui/x-data-grid-pro/locales';
import Popup from "../components/ui/Popup";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { formatNumber } from '../util/index'
import Button from './ui/Button';
import { useTranslation } from 'react-i18next';

export default function BillOfMaterial() {
    const apiRef = useGridApiRef();
    const [list, setList] = useState([]);
    const navigate =  useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();

    const [popupActive, setPopupActive] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [popupTitle, setPopupTitle] = useState("");

    const [reload, setReload] = React.useState(false);
    const [mancoCount, setMancoCount] = React.useState(0);
    const [mancoMessage, setMancoMessage] = React.useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    let operations = false;
    if (location.pathname.includes('/operations/')) {
      operations = true;
    }

    const handleSelection = (newSelection) => {
      if (newSelection[0] !== selectedRow) {
        setMancoCount(0)
        setMancoMessage("")
      }
      if (newSelection.length > 0) {
        setSelectedRow(newSelection[0])

      }
      if (newSelection.length === 0) {
        setSelectedRow(null)
      }
    }

    const handleKeyDown = (event) => {
      // TODO not sure why this is needed
      if (event.key === " " ||
          event.code === "Space" ||
          event.keyCode === 32
      ) {
          event.stopPropagation();
        }
    }

    const popupCallback = () => {
      setPopupText("");
      setPopupActive(false);
      setReload(true)
      setSelectedRow(null)
      setMancoCount(0)
      setMancoMessage("")
    }

    const handleMancoCount = (value, maxValue, currentValue) => {
      const totalMaxValue = Number(maxValue) - Number(currentValue);

      // When subtracting 1 after float happened
      if (Number(value) < 0 && Number(value > -1)) {
        setMancoCount(0)
      }

      if (Number(value) < 0) {
        return;
      }

      if (Number(value) > Number(totalMaxValue)) {
        if ((Number(value) - totalMaxValue > 0)) {
          value = totalMaxValue;
        } else {
          return;
        }
      }

      setMancoCount(Number(value))
    }

    const handleManualMancoCount = (value, maxValue, currentValue) => {
      value = value.replace(',', '.');
      const totalMaxValue = Number(maxValue) - Number(currentValue);

      if (!/^\d*\.?\d*$/.test(value)) {
          return;
      }

      if (Number(value) > Number(totalMaxValue)) {
        return;
      }

      setMancoCount(value)
    }

    const handleMancoMessage = (event) => {
      setMancoMessage(event.target.value);
    }

    const onSubmitMissing = (row) => {
      if (!/^\d*\.?\d*$/.test(mancoCount)) {
        return;
      }

      if (mancoCount <= 0) {
        return;
      }

      const customId = row.ProdHeaderDossierCode.trim() + "|" + row.ProdBOMLineNr
      axios.post(process.env.REACT_APP_API_URL + "/isah/productiondossier/missing", {
        key: customId,
        value: mancoCount,
      })
     .then(response => {
         setPopupTitle(t("Gelukt"));
         setPopupText(t("Het aantal manco is opgeslagen."));
         setPopupActive(true);
     })
     .catch(error => {
         setPopupTitle(t("Mislukt"));
         setPopupText(t("Er is iets fout gegaan. Probeer het later nog eens of meld dit bij onze IT support desk"));
         setPopupActive(true);
     });
    }

    const onSubmitDefect = (row) => {
        const customId = row.ProdHeaderDossierCode.trim() + "|" + row.ProdBOMLineNr

        axios.post(process.env.REACT_APP_API_URL + "/isah/productiondossier/defect", {
          key: customId,
          value: mancoMessage,
        })
        .then(response => {
            setPopupTitle(t("Gelukt"));
            setPopupText(t("Het defect is opgeslagen."));
            setPopupActive(true);
        })
        .catch(error => {
            setPopupTitle(t("Mislukt"));
            setPopupText(t("Er is iets fout gegaan. Probeer het later nog eens of meld dit bij onze IT support desk"));
            setPopupActive(true);
        });
    }


    const dataGridColumns = [
        {
          field: 'LineNr',
          headerName: 'Regel',
          width: 100,
          renderHeaderFilter: () => null,
        },
        {
          field:  'SubPartCode',
          headerName: 'Artikel nr.',
          width: 140,
        },
        {
          field: 'Description',
          headerName: 'Omschrijving',
          width: 250,
        },
        {
          field: 'PartDescription',
          headerName: 'Artikelomschrijving',
          width: 250,
        },
        {
          field: 'Remark',
          headerName: 'Opmerking',
          width: 250,
        },
        {
          field: 'Qty',
          headerName: 'Productie aantal',
          align: 'right',
          headerAlign: 'right',
          renderHeaderFilter: () => null,
          valueFormatter: (value) => formatNumber(value),
          width: 150
        },
        {
          field:  'PartUnit',
          headerName: 'Eenheid',
          width: 100,
          renderHeaderFilter: () => null,
        },
        {
          field: operations ? 'Omschr. Mankement' : 'Manco',
          headerName: operations ? 'Mankement' : 'Manco',
          align: 'right',
          headerAlign: 'right',
          width: operations ? 100 : 80,
          renderHeaderFilter: () => null,
          valueFormatter: (value) => {
            if (value !== 'Manco') {
              return value;
            }
            return formatNumber(value)
          },
        },
        {
          field: 'MancoQty',
          headerName: operations ? 'Toelichting' : 'Aantal manco',
          width: operations ? 200 : 165,
          renderHeaderFilter: () => null,
          renderCell: (params) => {
            const rowIndex = params.id;
            if (rowIndex === selectedRow) {
              return operations ?
                (
                  <div>
                    <textarea
                      onChange={(event) => handleMancoMessage(event)}
                      onKeyDown={(event) => handleKeyDown(event)}
                      value={mancoMessage}
                      style={{fontSize: "18px"}}
                      placeholder={t("Beschrijf aantal & defect van dit artikel")}
                      maxLength="60"
                      cols="15"
                      rows="4"
                    />
                  </div>
                )
              :
                (
                  <div className="manco-count-input" style={{ display: 'flex', alignItems: 'center' }}>
                    <button onClick={() => handleMancoCount(Number(mancoCount) - 1, params.row.Qty, params.row.Manco)}>-</button>
                    <input
                      type="text"
                      value={mancoCount}
                      onChange={(e) => {
                        handleManualMancoCount(e.target.value, params.row.Qty, params.row.Manco)
                      }}
                      style={{ width: '50px', textAlign: 'center' }}
                    />
                    <button onClick={() => handleMancoCount(Number(mancoCount) + 1, params.row.Qty, params.row.Manco)}>+</button>
                    <button className="all" onClick={() => handleMancoCount(Number(params.row.Qty - params.row.Manco), params.row.Qty, params.row.Manco)}>max</button>
                  </div>
                );
            } else {
              return null;
            }
          }
        },
        {
          field: 'operationButton',
          headerName: '',
          width: 150,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const rowIndex = params.id;
            if (rowIndex === selectedRow) {
              if (operations) {
                return (<Button onClick={() => onSubmitDefect(params.row)} variant="secondary">{t('Opslaan')}</Button>)
              }
              return (<Button onClick={() => onSubmitMissing(params.row)} variant="secondary">{t('Opslaan')}</Button>)

            } else {
              return null;
            }
          }
        }
    ];

    useEffect(() => {
        setReload(false);
        const fetchData = async () => {
          try {
           const response = await axios.get(process.env.REACT_APP_API_URL + "/isah/billofmaterials/" + params.dossiercode + "/" + params.bomline);
           setList(response.data);

          } catch (error) {
            console.error("Error:", error);
          }
        };

        fetchData();
      }, [params, reload]);

    return (
      <div>
        <Button variant="secondary" icon={false} onClick={() => navigate('/manco-registratie/' + params.salesNumber)}>terug naar overzicht</Button>
        <h1>Bill of Material {params?.ordnr ? "| " + params?.ordnr : ""}</h1>
        <div className={operations ? "list operations" : "list"}>
        <DataGridPro
            apiRef={apiRef}
            getRowId={(row) => row.LineNr}
            rows={list}
            headerFilters
            disableColumnFilter
            onRowSelectionModelChange={handleSelection}
            columns={dataGridColumns}
            localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
            initialState={{
                pagination: {
                  paginationModel: {
                      pageSize: 30,
                  },
                }
            }}
            pageSizeOptions={[30, 60, 90]}
          />
        </div>
        <Popup
            active={popupActive}
            title={popupTitle}
            text={popupText}
            callback={popupCallback}
            confirmText={t("ok")}
        />
      </div>
    )
}
