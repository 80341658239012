export function determineTextLines(multiLineText) {
    let multiLineArray = multiLineText.split('\n');

    // remove leading blank lines
    let textFound = false;
    let startIndex = 0;
    let n = 0;
    while (!textFound && n < multiLineArray.length) {
        if (multiLineArray[n].trim() !== '') {
            textFound = true;
        } else {
            n++;
        }
    }
    if (textFound) {
        startIndex = n;
    }

    // remove trailing blank lines
    textFound = false;
    let endIndex = multiLineArray.length - 1;
    n = endIndex;
    while (!textFound && n >= 0) {
        if (multiLineArray[n].trim() !== '') {
            textFound = true;
        } else {
            n--;
        }
    }
    if (textFound) {
        endIndex = n;
    }

    let result = [];
    for (let i = startIndex; i <= endIndex; i++) {
        result.push(multiLineArray[i]);
    }

    return result;
}
