import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function NavigationItem({ path, text, open, icon: IconComponent, className }) {
    const location = useLocation();
    const locationSegments = location.pathname.split("/");
    const pathSegments = path.split("/");

    let isActive = pathSegments[1] === locationSegments[1];
    if (className === "subitem" && isActive) {
        isActive = pathSegments[2] === locationSegments[2];
    }

    return (
        <Link
            to={path}
            className={`menu-item ${isActive ? "active" : "inactive"} ${className}`}
        >
            <IconComponent/>
            <div>
                {open && <span>{text}</span>}
            </div>
        </Link>
    );
}
