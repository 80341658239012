import React, { useEffect, useState } from 'react';
import Button from './ui/Button';
import { useTranslation } from 'react-i18next';
import  GanttChart from './Gantt';
import axios from 'axios';

const Planning = () => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);

    function getFilename(response) {
        const disposition = response.headers['content-disposition'] || response.headers['Content-Disposition'];
        let filename = 'masterplanning.xlsx';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        return filename;
    }

    const downloadExcel = (url) => {
        axios({
            url: process.env.REACT_APP_API_URL + url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getFilename(response)); // or dynamically set the filename from the response if available
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => console.error('Download error:', error));
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
           const response = await axios.get(process.env.REACT_APP_API_URL + "/isah/planning/");
           setData(response.data);

          } catch (error) {
            console.error("Error:", error);
          }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>Planning</h1>
            <div>
                <Button onClick={() => downloadExcel("/isah/planning/excel")} variant="primary" color='primary' type="submit">{t("Download lijst")}</Button>
                &nbsp;
                &nbsp;
                <Button onClick={() => downloadExcel("/isah/planning/sortedexcel")} variant="primary" color='primary' type="submit">{t("Download planning")}</Button>
                &nbsp;
                &nbsp;
                <Button onClick={() => downloadExcel("/isah/planning/sortedflexicomexcel")} variant="primary" color='primary' type="submit">{t("Download Flexicom planning")}</Button>
            </div>
            <br/>
            <GanttChart data={data}/>
        </div>
    )
}

export default Planning;
