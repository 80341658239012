import React from 'react';

export default function FileUpload({onFileChange, id, label, title, multiple}) {
    return (
        <div>
            {
                title &&
                <p><strong>{title}</strong></p>
            }
            {
                label &&
                <label className='input-file-button'  htmlFor={id}>{label}</label>
            }
            {/* Note: the onClick() is needed if the end user wants to select the same files again  */}
            <input
                id={id}
                accept="application/pdf,
                        application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/rtf,
                        application/vnd.oasis.opendocument.text,
                        message/rfc822,
                        text/plain,
                        text/csv,
                        image/jpeg,
                        image/png,
                        image/gif"
                type="file"
                onChange={onFileChange}
                style={{visibility: 'hidden'}}
                multiple
                onClick={(event)=> {
                    event.target.value = null
                }}
            />
        </div>
    );
}
