import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function ImageComponent ({ imageId }) {
    const [imageSrc, setImageSrc] = useState(null)
    useEffect(() => {
        const fetchImage = async () => {
            if(imageId) {
                try {
                    const imageResponse = await axios.get(process.env.REACT_APP_API_URL + "/isah/image/" +  imageId, { responseType: "blob" })
                    // const base64Data = imageResponse.data;
                    // const contentType = imageResponse.headers['content-type'];
                    // const dataURL = `data:${contentType};base64,${base64Data}`;
                    // setImageSrc(dataURL);
                    const objectURL = URL.createObjectURL(imageResponse.data);
                    setImageSrc(objectURL);

                } catch (error) {
                    console.error("Error fetching clothes:", error);
                }
            } else {
                setImageSrc(process.env.PUBLIC_URL + 'images/logo_demeeuw_placeholder.png');
            }
        }

        fetchImage();
    }, [imageId]);

    return (
        <div>
           <img src={imageSrc} alt="kledingstuk"/>
        </div>
    )
}
