import React, { useEffect, useState } from 'react';
import PowerBIReport from './ui/PowerBIReport';
import axios from 'axios';

const Logistics = () => {
    const [embedToken, setEmbedToken] = useState();
    const [embedUrl, setEmbedUrl] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/azure/powerbi/reporturl");
                setEmbedToken(response.data['embedToken']);
                setEmbedUrl(response.data['embedUrl']);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
        <h1>Logistiek</h1>
        {
            embedToken ?
                <PowerBIReport
                    reportId="e6faeb69-5a22-4886-a519-3dc3053c44f8"
                    embedUrl={embedUrl}
                    // accessToken={accessToken}
                    accessToken={embedToken}
                />
        :
            null
        }
        </div>
    );
};

export default Logistics;
