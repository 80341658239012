export function formatIntegerAmount(integerAlfa) {

    const digits = '0123456789';
    const length = integerAlfa.length;

    if (length === 0) {
      return '';
    }

    let result = integerAlfa;
    const lastChar = result.substring(length - 1, length);
    const idx = digits.indexOf(lastChar);
    if (idx === -1) {
      result = result.substring(0, length - 1);
    }

    return result;
}

export function displayEuropeanPrice(number) {
  if (number === null || number === undefined) {
    return '';
  }
  return number.toFixed(2).replace('.', ',');
}

export function displayFloat(number) {
  if (number === null || number === undefined) {
    return '';
  }
  return number.replace('.', ',');
}


export function formatAmountWith2Decimals(amountAlfa) {
  const digits = '0123456789';
  const length = amountAlfa.length;

  if (length === 0) {
    return '';
  }

  let result = amountAlfa;

  // Both . and , are allowed as input
  // Redisplay always uses ,
  result = result.replace('.', ',');

  const lastChar = result.substring(length - 1, length);
  if (lastChar === ',') {
    if (length === 1) {
      return '';
    }

    // check for double occurrence of ,
    const idx = result.indexOf(',');
    if (idx !== (length - 1)) {
      result = result.substring(0, length - 1);
      return result;
    }
  }

  if (lastChar !== ',') {
    let idx = digits.indexOf(lastChar);
    if (idx === -1) {
      result = result.substring(0, length - 1);
      return result;
    }
    idx = result.indexOf(',');
    if (idx !== -1 && idx === (length - 4)) {
      // more than 2 decimals entered after ,
      result = result.substring(0, length - 1);
      return result;
    }
  }
  return result;
}
