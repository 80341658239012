import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // Set the timeout value (10 seconds in this example)
});

// Add a request interceptor
axiosInstance.interceptors.request.use(request => {
    // You can add any configurations or headers here before the request is sent
    return request;
}, error => {
    // Handle request error here
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
});

export async function fetchData(endpoint, method = 'GET', data = {}, params = {}) {
  try {
    const response = await axiosInstance({
      url: endpoint,
      method,
      data,
      params,
    });
    return response.data;
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      error.message = 'Data ophalen duurt te lang. Zorg dat je op de remote desktop (AVD) of het interne netwerk van De Meeuw ingelogd bent.';
    }
    // Here you can handle errors based on status code or error message
    // For example, redirecting user to login if error is 401 (unauthorized)
    // Or showing a specific error message if it's a 404 (not found)
    throw error;
  }
}
