import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import TrashIcon from '../icons/TrashIcon';

import Popup from "../../components/ui/Popup";
import { uc } from "../../util"
import { useMsal } from '@azure/msal-react';

export default function Scan({capacityGroup, scanType}) {
    const { accounts } = useMsal();
    const account = accounts[0] || false;
    const [value, setValue] = useState("");
    const [list, setList] = useState([]);
    const [popupActive, setPopupActive] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [activeRecord, setActiveRecord] = useState(null);
    const scanInputRef = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (value === "") {
            scanInputRef.current.focus();
        }
    }, [value])

    function calculateTimeSpan(startTime, endTime) {
        const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
        const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

        const startTotalSeconds = startHours * 3600 + startMinutes * 60 + startSeconds;
        const endTotalSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;
        let diffInSeconds = endTotalSeconds - startTotalSeconds;

        if (diffInSeconds < 0) {
            diffInSeconds += 24 * 3600;
        }

        const hours = Math.floor(diffInSeconds / 3600);
        diffInSeconds %= 3600;
        let minutes = Math.floor(diffInSeconds / 60);
        let seconds = diffInSeconds % 60;
        if (minutes < 10) {
            minutes = "0" + minutes
        }
        if (seconds < 10) {
            seconds = "0" + seconds
        }
        return [hours, minutes, seconds].join(":");
    }

    useEffect(() => {
        scanInputRef.current.focus();
        if (capacityGroup) {
            axios.get(
                process.env.REACT_APP_API_URL + "/demeeuw/scanner/" + capacityGroup
            ).then(result => {
                setList(result.data);
            }).catch(error => {
                console.error("Error: ", error)
            })
        }
    }, [capacityGroup]);

    const createRecord = (item) => {
        axios.post(
            process.env.REACT_APP_API_URL + "/demeeuw/scanner", item
        ).then(result => {
            // console.log(result)
        }).catch(error => {
            console.error("Error: ", error)
        }).finally(() => {

        })
    }

    const updateRecord = (item) => {
        axios.put(
            process.env.REACT_APP_API_URL + "/demeeuw/scanner", item
        ).then(result => {
            // console.log(result)
        }).catch(error => {
            console.error("Error: ", error)
        }).finally(() => {

        })
    }

    function addValue() {
        const index = list.findIndex(item => item.production_dossiercode === value);
        const date = new Date();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        if (index === -1) {
            setList(prevList => [{
                production_dossiercode: value,
                capacity_group: capacityGroup,
                starttime: dateTimeString,
            }, ...prevList]);
        } else {
            setList(prevList => prevList.map((item, idx) => {
                if (idx === index) {
                    return { ...item, endtime: dateTimeString  };
                }
                return item;
            }));
        }

        const record = {
            production_dossiercode: value,
            capacity_group: capacityGroup,
            updated_by: account.username,
        };

        if (index === -1 ) {
            createRecord({
                ...record,
                created_by: account.username
            })
        } else {
            updateRecord(record)
        }

        setValue("");
    }

    const handleBlur = (event) => {
        event.preventDefault();
        if (value === "") {
            return
        }
        addValue();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            if (value !== '') {
                addValue();
            }
        }
    };

    const handleDelete = (productionDossierCode) => {
        setPopupActive(true);
        setActiveRecord(productionDossierCode);
        setPopupText(`Weet u zeker dat u "${productionDossierCode}" wilt verwijderen`);
    }

    const popupCallback = (action) => {
        if (action === "confirm") {
            const newList = list.filter(item => item.production_dossiercode !== activeRecord);

            setList(newList);
            axios.put(
                process.env.REACT_APP_API_URL + "/demeeuw/scanner/" + capacityGroup + "/" + activeRecord,
                {
                    updated_by: account.username,
                    deleted_by: account.username,
                }
            ).then(result => {
                // console.log(result)
            }).catch(error => {
                console.error("Error: ", error)
            }).finally(() => {

            })
        }
        setPopupActive(false);
        setActiveRecord(null);
        setPopupText("");
    }

    return (
        <>
            <div className="inputGroup">
                <label htmlFor="scanInput">Scan</label>
                <input
                    id="scanInput"
                    label="Scan"
                    ref={scanInputRef}
                    className='customTextInput'
                    inputMode='none'
                    value={value}
                    onChange={(event) => {
                        event.preventDefault();
                        setValue(event.target.value);
                    }}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            </div>

            <div className="listview listview-small">
                <div className="listview-item listview-header">
                    <div>
                        <span><strong>M-nummer</strong></span>
                    </div>
                    <div className="mobile">
                        <span><strong>Tijd</strong></span>
                    </div>
                    {/* <div className="desktop">
                        <span><strong>Datum</strong></span>
                    </div> */}
                    <div className="desktop">
                        <span><strong>Starttijd</strong></span>
                    </div>
                    <div className="desktop">
                        <span><strong>Eindtijd</strong></span>
                    </div>
                    <div>
                        <span><strong>Tijdsduur</strong></span>
                    </div>
                    <div className="icon">
                    </div>
                </div>
                {
                    list.map((item, key) => {
                        let starttime = item.starttime ? item.starttime.split(' ')[1] : "";
                        let endtime = item.endtime ? item.endtime.split(' ')[1] : "";
                        return (
                            <div key={key} className="listview-item">
                            <div>
                                <span>{item.production_dossiercode}</span>
                            </div>
                            <div className="mobile">
                                {/* <span>{item.date}</span><br/> */}
                                <span>{starttime}</span><br/>
                                <span>{endtime}</span>
                            </div>
                            {/* <div className="desktop">
                                <span>{item.date}</span>
                            </div> */}
                            <div className="desktop">
                                <span>{starttime}</span>
                            </div>
                            <div className="desktop">
                                <span>{endtime || ""}</span>
                            </div>
                            <div>
                                <span>{endtime ? calculateTimeSpan(starttime, endtime) : ""}
                                </span>
                            </div>
                            <div onClick={() => handleDelete(item.production_dossiercode) } className="icon">
                                <TrashIcon/>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
            <Popup
                active={popupActive}
                title={uc(t("verwijderen"))}
                text={popupText}
                callback={popupCallback}
                confirmText={t("ok")}
                cancelText={t("nee")}
            />
        </>
    )
}
