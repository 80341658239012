import React, { useState } from 'react';
// import BlockIcon from '@mui/icons-material/Block';
import Scan from './Scan';
import { uc } from '../../util'
import ListIcon from '../icons/ListIcon';
import Button from '../../components/ui/Button';
import { useNavigate } from "react-router-dom";

export default function ScanIndex() {
	const [capacityGroup, setCapacityGroup] = useState("");
    const [scanType, setScanType] = useState("");
    let navigate = useNavigate();

    const now = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('nl-NL', options).format(now);

    const capacityGroupList = ['panelen', 'lassen', 'beton', 'daken', 'casco', 'afbouw'];

    const handleCapacityGroupChange = (event) => {
        setCapacityGroup(event.target.value);
    };

    const handleScanTypeChange = (event) => {
        setScanType(event.target.value);
    };

    return (
        <>
            <h1>Cycle time</h1>
            <div className="inputGroup">
                <label htmlFor="capacityGroup">Capaciteits groep</label>
                <select id="capacityGroup" className="custom-select" value={capacityGroup} onChange={handleCapacityGroupChange}>
                    <option value="" disabled>--</option>
                    {capacityGroupList.map(item => <option key={item} value={item}>{uc(item)}</option>)}
                </select>
            </div>
            {/* TODO find out if we need scan type */}
            <fieldset style = {{display: "none"}} disabled>
                <legend>Scan type:</legend>
                <div>
                    <input type="radio" id="scantypeChoice1" name="scantype" value="start" onChange={handleScanTypeChange} /* checked={scanType === "start"} */ />
                    <label htmlFor="scantypeChoice1">scan start</label>
                </div>
                <div>
                    <input type="radio" id="scantypeChoice2" name="scantype" value="stop" onChange={handleScanTypeChange} /* checked={scanType === "stop"} */  />
                    <label htmlFor="scantypeChoice2">scan stop</label>
                </div>
                <div>
                    <input type="radio" id="scantypeChoice3" name="scantype" value="both" onChange={handleScanTypeChange} checked={true} /* checked={scanType === "both"} */ />
                    <label htmlFor="scantypeChoice3">scan start/stop</label>
                </div>
            </fieldset>
            <div>
                <strong>Vandaag:</strong> <i>{formattedDate}</i>
                <Button inline={true} icon={false} onClick={() => navigate('/scanner/overzicht')}><ListIcon/></Button>
            </div>
            { capacityGroup !== "" /* && scanType !== "" */ ?
                <Scan capacityGroup={capacityGroup} scanType={scanType}/>
                :
                null
            }

        </>
    )
}
