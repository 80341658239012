import React from 'react';

import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

import Toolbar from '@mui/material/Toolbar';
import { useIsAuthenticated } from '@azure/msal-react';

export default function AppBar({open, setOpen}) {
    const isAuthenticated = useIsAuthenticated();

    return (
        <MuiAppBar className="app-bar" position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                   }}
                >
                    <MenuIcon />
                </IconButton>
                <div className="navbar">
                    <a className="navbar-logo" href="/">
                        <img src="/images/vdldemeeuw-logo.png" alt="logo VDL De Meeuw"/>
                    </a>
                    <a className="navbar-brand" href="/">
                        |&nbsp;&nbsp;Portal
                    </a>
                </div>
                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </Toolbar>

        </MuiAppBar>
    );
}
