import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DocumentList from "./documentList";
import axios from 'axios';

import { DataGridPro, nlNL } from '@mui/x-data-grid-pro';
import { uc, parseISODate } from '../../util';
import { displayFloat, displayEuropeanPrice } from '../../util/formatHelper';
import { useTranslation } from "react-i18next";

import PanelField from "../ui/PanelField";
import ExpandMoreToggle from "../ui/ExpandMoreToggle";

function TabPanel({ children, value, index }) {
    return (
        <div className="tabpanel-panel" hidden={value !== index}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

const PurchaseDocument = (props) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const { t } = useTranslation();
    let routerParams = useParams();
    let orderId = routerParams?.orderId;

    const paneList = [
        'Algemeen',
        'Specificatie',
        'Prijsherkomst',
        'Voortgang',
        // 'Projecten',
        // 'Memo',
        // 'Teksten',
        // 'Periode',
    ]

    const dataGridColumns = [
        {
            field: 'Inkooporder',
            headerName: 'Inkooporder',
            width: 90,
            renderCell: (params) =>  orderId
        },
        {
            field: 'PurQuotNr',
            headerName: 'Inkoopofferte',
            width: 90,
        },
        {
            field: 'PDPartLineNr',
            headerName: 'Regel',
            width: 70,
        },
        {
            field: 'SubLineCode',
            headerName: 'Subregel',
            width: 70,
        },
        {
            field: 'Bestemming',
            headerName: 'Bestemming',
            width: 200,
        },
        {
            field: 'PartCode',
            headerName: 'Artikel',
            width: 100,
        },
        {
            field: 'Description',
            headerName: 'Omschrijving',
            width: 200,
        },
        {
            field: 'ReceiptCompletedDate',
            headerName: 'Ontvangstdatum',
            width: 200,
        },
        {
            field: 'Maat',
            headerName: 'Maat',
            width: 80,
        },
        {
            field: 'ReceiptCompletedInd',
            headerName: 'Bevestigd',
            width: 70,
            renderCell: (params) => params.row.ReceiptCompletedInd === 1 ? 'ja' : 'nee'
        },
        {
            field: 'Qty',
            headerName: 'Aantal',
            width: 70,
            renderCell: (params) => displayFloat(params.row.Qty)
        },
        {
            field: 'CurrPrice',
            headerName: 'Prijs per',
            width: 70,
            renderCell: (params) => displayFloat(params.row.CurrPrice)
        },
        {
            field: 'TotalAmount',
            headerName: 'Totaalbedrag',
            width: 120,
            renderCell: (params) => displayEuropeanPrice(params.row.Qty * params.row.CurrPrice)
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/isah/purchasedocument/" + orderId);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchData();
    }, [orderId]);

    if (loading) {
        return <div>
            <h1>Inkooporder ...</h1>
            <div className="loader">Loading...</div>
        </div>
    }

    function handleRowClick(params, event) {
        setSelectedRow(params.row)
    }

    function handleTabChange(event) {
        setActiveTab(Number(event.target.value))
    }

    function checkbox(value) {
        return value === 1 ?
            <input type="checkbox" checked={true} disabled />
        :
            <input type="checkbox" checked={false} disabled />
    }

    return (
        <div>
            <h1>{uc(t('inkooporder'))}  {orderId || ""}</h1>
            <div className="tabpanel panel" style={{padding: "10px"}}>
                <h4>Algemeen</h4>
                <div className="fieldset-container">
                    <div className="fieldset-row">
                        <fieldset className="fieldset-100">
                            <legend>Inkoop</legend>
                            <PanelField name="Inkooporder" value={`${orderId} | ${parseISODate(data.CreDate, true)}`}/>
                        </fieldset>
                    </div>
                </div>
                <div className="row" onClick={handleToggle} style={{cursor: "pointer", alignItems: 'center'}}>
                    <h4>Inkoopregels</h4>
                    <ExpandMoreToggle isOpen={isOpen}/>
                </div>
                {isOpen && <div style={{fontSize: "14px", marginTop: "10px", marginBottom: "20px"}}><i>{uc(t('selecteer een inkoopregel voor meer informatie.'))}</i></div>}
                {
                    isOpen && (
                        <DataGridPro style={{height: '40vh', backgroundColor: 'white', marginBottom: '20px'}}
                            rows={data.list}
                            rowHeight={40}
                            columns={dataGridColumns}
                            getRowId={(row) => row.PurDocCode + row.PDPartLineNr}
                            localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                            onRowClick={handleRowClick}
                        />
                    )
                }
                {
                    isOpen && selectedRow ?
                        <div className="panel tabpanel" style={{backgroundColor: 'white'}}>
                            <div className="tabpanel-header tabpanel-desktop">
                                {
                                    paneList.map((pane, key) => {
                                        return <button key={key} className={ activeTab === key ? "active" : ""} onClick={() => setActiveTab(key)}>{pane}</button>
                                    })
                                }
                            </div>
                            <div className="tabpanel-header tabpanel-mobile">
                                <select value={activeTab} onChange={handleTabChange}>
                                    {
                                        paneList.map((pane, key) => {
                                            return <option key={key} value={key}>{pane}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <TabPanel value={activeTab} index={0}>
                                <div className="fieldset-container">
                                    <div className="fieldset-row">
                                        <fieldset className="fieldset-40">
                                            <PanelField name="Inkooporder" value={data.Inkooporder}/>
                                            <PanelField name="Inkoopofferte" value={selectedRow.PurQuotNr}/>
                                            <PanelField name="Inkoopregel" value={`${selectedRow.PDPartLineNr ?? ""} - ${selectedRow.SubLineCode ?? ""}`}/>
                                            <PanelField name="Bestemming" value={selectedRow.Bestemming}/>
                                            <PanelField name="Artikel" value={selectedRow.PartCode}/>
                                            <PanelField name="Omschrijving" value={selectedRow.Description}/>
                                        </fieldset>
                                        <div className="fieldset-container">
                                            {/* <div className="fieldset-row">
                                                <fieldset className="fieldset-40">
                                                </fieldset>
                                                <fieldset className="fieldset-60">
                                                </fieldset>

                                            </div> */}
                                            <div className="fieldset-row">
                                                <fieldset className="fieldset-100">
                                                    <PanelField name="Aantal" value={displayFloat(selectedRow.Qty)}/>
                                                    <PanelField name="Prijs per" value={displayFloat(selectedRow.CurrPrice)}/>
                                                    <PanelField name="Totaalbedrag" value={ displayEuropeanPrice(selectedRow.Qty * selectedRow.CurrPrice)}/>
                                                </fieldset>
                                            </div>
                                            {/* <div className="fieldset-row">
                                                <fieldset className="fieldset-100">
                                                </fieldset>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                <div className="fieldset-container">
                                    <div className="fieldset-row">
                                        <fieldset className="fieldset-100">
                                            <PanelField name="Inkoopstatus" value={selectedRow.PurchaseStatusCode}/>
                                            <PanelField name="Routing" value={selectedRow.StandPurRoutingCode}/>
                                            <PanelField name="Orderdatum" value={parseISODate(selectedRow.CreDate, true)}/>
                                            <PanelField name="Offertedatum" value={parseISODate(selectedRow.CreDateQuot, true)}/>
                                            <PanelField name="Boekdatum" value={parseISODate(selectedRow.BookDate, true)}/>
                                            <PanelField name="Lotnummer" value={selectedRow.LotNr}/>
                                            <PanelField name="Certificaat" value={selectedRow.CertificateCode}/>
                                            <PanelField name="Oorspronkelijke inkoopregel" value={selectedRow.LotNr}/>
                                            <PanelField name="Vekoopstatus omschrijving" value={selectedRow['Vekoopstatus omschrijving']}/>
                                        </fieldset>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={activeTab} index={2}>
                                <div className="fieldset-container">
                                    <div className="fieldset-row">
                                        <fieldset className="fieldset-100">
                                            <PanelField name="Prijsherkomst" value={selectedRow.PurPriceProvCode}/>
                                            <PanelField name="Specificatie" value={selectedRow.PurPriceProvDesc}/>
                                        </fieldset>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={activeTab} index={3}>
                                {/* Voortgang */}
                                <div className="fieldset-container">
                                    <div className="fieldset-row">
                                        <fieldset className="fieldset-60">
                                            <legend>Inkoopregel</legend>
                                            <PanelField name="Aantal" value={selectedRow.Qty}/>
                                            <PanelField name="Bevestigd" value={checkbox(selectedRow.ConfirmedInd)}/>
                                            <PanelField name="Oorspronkelijke ontvangstdatum" value={parseISODate(selectedRow.InitReceiptDate, true)}/>
                                        </fieldset>
                                        <fieldset className="fieldset-40">
                                            <legend>Ontvangst</legend>
                                            <PanelField name="Gereed" value={checkbox(selectedRow.ReceiptCompletedInd)}/>
                                            <PanelField name="Datum" value={parseISODate(selectedRow.ReceiptCompletedDate, true)}/>
                                            <PanelField name="Financieel gereed" value={checkbox(selectedRow.FinReceiptCompletedInd)}/>
                                            <PanelField name="Datum" value={parseISODate(selectedRow.FinReceiptCompletedDate, true)}/>
                                        </fieldset>
                                    </div>
                                    <div className="fieldset-row">
                                        <fieldset className="fieldset-60">
                                            <legend>Financiële informatie</legend>
                                            <PanelField name="Aangegane verplichting" value={selectedRow.BasicEnteredObliAmount}/>
                                            <PanelField name="Nog te ontvangen goederen" value={selectedRow.BasicCurrGoodsToBeRecAmount}/>
                                            <PanelField name="Nog te ontvangen inkoopfacturen" value={selectedRow.BasicCurrInvToBeRecAmount}/>
                                        </fieldset>
                                        <fieldset className="fieldset-40">
                                            <legend>Inkoopfactuur</legend>
                                            <PanelField name="Gereed" value={checkbox(selectedRow.PurInvCompletedInd)}/>
                                            <PanelField name="Datum" value={parseISODate(selectedRow.PurInvCompletedDate, true)}/>
                                        </fieldset>
                                    </div>
                                </div>

                            </TabPanel>
                            <TabPanel value={activeTab} index={4}>
                                Projecten...
                            </TabPanel>
                            <TabPanel value={activeTab} index={5}>
                                Memo...
                            </TabPanel>
                            <TabPanel value={activeTab} index={6}>
                                Teksten...
                            </TabPanel>
                            <TabPanel value={activeTab} index={7}>
                                Periode...
                            </TabPanel>
                        </div>
                    :
                        null
                }

                <DocumentList list={data.documentList}/>
            </div>
        </div>
    );
};

export default PurchaseDocument;
