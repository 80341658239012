import React from 'react'

export default function PanelField({name, value}) {
    return (
        <div className="panel-field">
            <div className="panel-field-name">{name}:</div>
            <div className="panel-field-value">{value}</div>
        </div>
    )
}
