import React from 'react';

export default function NotFound() {
    return (
        <>
            <h1>Oh oh - 404</h1>
            <p>De pagina die je zoekt kan niet gevonden worden!</p>
        </>
    );
}
