import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import Popup from "../components/ui/Popup";
import ClothesList from  "../components/ClothesList"
import ImageComponent from  "../components/ImageComponent"
import Autocomplete from '@mui/material/Autocomplete';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

export default function OrderForm() {
    const { accounts } = useMsal();
    const account = accounts[0];
    const [popupActive, setPopupActive] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [loading, setLoading] = useState(true);
    const [popupTitle, setPopupTitle] = useState("");
    const [clothesList, setClothesList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [employee, setEmployee] = useState();
    const [orderHistory, setOrderHistory] = useState([]);
  //  const [loaded, setLoaded] = useState(false);
	const { control, handleSubmit, formState: { errors }, setValue, getValues, watch, clearErrors, reset } = useForm();
    const { t } = useTranslation();

    const subtractValues = (maxQty, orderedQtyYear) => {
        const parsedOrderedQtyYear = parseFloat(orderedQtyYear);

        // Check if parsedOrderedQtyYear is a valid number
        if (isNaN(parsedOrderedQtyYear)) {
            console.error('OrderedQtyYear is not a valid number');
            return;
        }
        let currentMaxQty = maxQty - parsedOrderedQtyYear;

        if ( currentMaxQty < 0 ) {
            currentMaxQty = 0;
        }
        return currentMaxQty;
    };

    const onError = (errors, e) => {
        const firstError = Object.keys(errors)[0];
        const errorElement = document.querySelector(`[name="${firstError}"]`);

        if (errorElement) {
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    };

    const fetchClothingPacket = () => {
        return axios.get(process.env.REACT_APP_API_URL + "/isah/clothes/" + employee.CostCenterCode)
    };

    const fetchOrderHistory = () => {
        return axios.get(process.env.REACT_APP_API_URL + "/isah/clothes/employee/" + employee.EmpId);
    };

    const fetchData = async () => {
        let isMounted = true;

        if(employeeList.length > 1) {
            setLoading(true);
        }

        try {
            // Execute all promises at the same time
            const results = await Promise.all([
                fetchClothingPacket(),
                fetchOrderHistory(employee.EmpId)
            ]);

            // first promise returns clothing data and the second order history
            // Set initial values
            const newFormValues = {};
            Object.keys(results[0].data).map(index => {
                let presetValue = results[1].data.find(element => element.PurPartGrpCode === index);
                if (presetValue && presetValue.PartCode) {
                    newFormValues[`${index}_size`] = presetValue.PartCode;
                } else {
                    newFormValues[`${index}_size`] = "";
                }
                newFormValues[`${index}_amount`] = 0;

            })

            reset(newFormValues)
            if (isMounted) {
                setClothesList(results[0].data);
                setOrderHistory(results[1].data);
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
            if (isMounted) {
                setLoading(false);
            }

        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }

        return () => {
            isMounted = false;
        };
    };

    useEffect(() => {
        setClothesList([]);
        setOrderHistory([]);
        clearErrors();
        reset();
        if (employee?.EmpId && employee?.CostCenterCode) {
            fetchData();
        }
    }, [employee]);

    const popupCallback = () => {
        setPopupText("");
        setPopupActive(false);
    }

    useEffect(() => {
        let url = process.env.REACT_APP_API_URL + "/isah/employee?email=" + account.username
       //  let urlEmp = process.env.REACT_APP_API_URL + "/isah/clothes/employee/" + account.empid
        if (account.idTokenClaims?.roles?.includes("Order.Clothes.Admin") || account.idTokenClaims?.roles?.includes("Order.Clothes.Manager")) {
            url = url + "&role=admin";
        }

        const fetchData = async () => {
          try {
            const response = await axios.get(url, { timeout: 5000 });
            setEmployeeList(response.data);
            setLoading(false);

            if (response.data.length === 1) {
                setEmployee(response.data[0])
            }
            // if (response.data.length === 0) {
            //     setLoading(false)
            // }

          } catch (error) {
            setLoading(false);
            console.error("Error fetching employee:", error);
                    // Log specific error messages based on the type of error
            if (error.code === 'ECONNABORTED') {
                console.error('Request timeout:', error.message);
            } else {
                console.error('Error fetching employee:', error.message);
            }
          }
        };

        fetchData();

        }, [account]
    );

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        };
      };

    const onSubmit = (data) => {
        let orderLines = [];

        for (const [key, value] of Object.entries(data)) {
            if (key.includes("_") && !!value) {
                const [prefix, suffix] = key.split("_");

                // Find an existing object in the array with the same PurPartGrpCode.
                let obj = orderLines.find(o => o.PurPartGrpCode === prefix);

                // If there is no existing object, create one.
                if (!obj) {
                    obj = { PurPartGrpCode: prefix };
                    orderLines.push(obj);
                }

                // Add the information to the object based on whether it's a size or amount.
                if (suffix === 'amount') {
                    obj.Qty = value
                } else if (suffix === 'size') {
                    obj.PartCode = value;
                }
            }
        }

        orderLines = orderLines.filter(item => item.Qty !== 0 && item.Qty !== undefined);
        if(orderLines.length === 0) {
            setPopupTitle(t("Bestelling Ontbreekt"));
            setPopupText(t("Geen aantallen ingevuld. Vul alstublieft de gewenste aantallen in en probeer opnieuw."));
            setPopupActive(true);
            return;
        }

        const formData = {
            "Buyer": employee.EmpId,
            "EmpId": employee.EmpId,
            "VendId": "11634",
            "CostCenterCode": employee.CostCenterCode,
            // "QuoteRef": data.remark, //
            // TEMP
            "QuoteRef": employee.Name + " (" + employee.FirstName + ") #" + employee.EmpId,
            "orderlines": orderLines,
        }

        axios.post(process.env.REACT_APP_API_URL + "/isah/order", formData)
            .then(response => {
                setPopupTitle(t("Gelukt"));
                setPopupText(t("Uw bestelling is geslaagd. U krijgt bericht van ons als de bestelling opgehaald kan worden."));
                setPopupActive(true);
                reset();
                setEmployee(undefined);
            })
            .catch(error => {
                setPopupTitle(t("Mislukt"));
                setPopupText(t("Er is iets fout gegaan. Probeer het later nog eens of meld dit bij onze IT support desk"));
                setPopupActive(true);
            });
	};

    if (loading) {
        return <div className="loader">Loading...</div>;
    }

    function excludeClothingCategory(employee, index) {
        const excludeForTemporaryWorkers = ["KBR1","KBR2","KBR3","KBR4","KJA1","KJA2","KJA3","KJA4","KSC1","KSC2","KHD1"];
        const excludeForHiredWorkers = ["KSC1"];

        const jobDescription = employee?.JobDescription.toLowerCase();
        if (jobDescription === "uitzendkracht") {
            return excludeForTemporaryWorkers.includes(index)
        } else if (jobDescription === "zzp") {
            return excludeForHiredWorkers.includes(index)
        } else if (jobDescription === "detachering") {
            return excludeForHiredWorkers.includes(index)
        } else {
            return false;
        }
    }

    return (
        <div>
            <Popup
                active={popupActive}
                title={popupTitle}
                text={popupText}
                callback={popupCallback}
                confirmText={t("ok")}
            />
            <h1>{t("Kleding Bestellen")}</h1>
            {employeeList.length === 0 ? (
                <div>
                    <p>Er is helaas geen gekoppelde werknemer voor jouw Microsoft account
                    gevonden, <a href="https://dmgroup.freshdesk.com">dien een ticket in bij
                    ICT</a> als dit onjuist is.</p>
                </div>
            ) : (
            <form className="Form" onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={handleKeyDown}>
                <div>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={employeeList}
                        getOptionLabel={(option) =>
                            (option.FirstName && option.Name) ? (option.Name + " (" + option.FirstName + ")") : "Selecteer een werknemer"
                        }
                        renderOption={(props, option) => (
                            <li {...props} key={option.EmpId}>
                                {option.Name + " (" + option.FirstName + ")"}
                            </li>
                        )}
                        sx={{ width: 300 }}
                        onChange={(event, option) => {
                            setEmployee(option);
                        }}
                        value={employee || null}
                        renderInput={(params) => <TextField {...params} label="Werknemer" />}
                    />
                </div>
                <div>
                    <TextField
                        label="E-mail"
                        variant="outlined"
                        value={employee?.Email || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                        className="Textfield TextfieldLarge"
                    />
                </div>
                <div>
                    <TextField
                        id="filled-read-only-input"
                        label="Voornaam"
                        value={employee?.FirstName || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                        className="Textfield TextfieldLarge"
                    />
                </div>
                <div>
                    <TextField
                        id="filled-read-only-input"
                        label="Achternaam"
                        value={employee?.Name || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                        className="Textfield TextfieldLarge"
                    />
                </div>
                <div>
                    <TextField
                        label="Kostenplaats"
                        variant="outlined"
                        value={employee && employee.CostCenterCode ? employee?.CostCenterCode + " " + employee?.costcenter : ""}
                        InputProps={{
                            readOnly: true,
                        }}
                        className="Textfield TextfieldLarge"

                    />
                </div>
                {
                    clothesList ?
                        <div className="clothing-list">
                            {Object.keys(clothesList).map(index => {
                                if (excludeClothingCategory(employee, index)) {
                                    return ;
                                }
                                let item = clothesList[index]
                                let maxVal = 0;
                                let presetValue = {};
                                if (orderHistory && Array.isArray(orderHistory)) {
                                    presetValue = orderHistory.find(element => element.PurPartGrpCode == index);
                                }
                                if (presetValue) {
                                    maxVal = subtractValues(presetValue.MaxQty, presetValue.OrderedQtyYear);
                                }

                                return (
                                    <div key={index} >
                                        <div>
                                            <h4>{item.name}</h4>
                                        </div>
                                        <div className="clothing-item">
                                            <ImageComponent imageId={item.picture}/>
                                            { item.list && item.list.length > 0 ? <ClothesList maxValue={maxVal} list={item.list} control={control} setValue={setValue} getValues={getValues} watch={watch} errors={errors}/> : null}
                                        </div>
                                    </div>
                                )})}
                        </div>
                    :
                        employee?.CostCenterCode ? "Geen pakket voor department code: " + employee.CostCenterCode + " gevonden" : null
                }
                <div>
                {Object.keys(errors).length > 0 && (
                    <div style={{ color: '#d32f2f', marginBottom: '1em' }}>
                        {t("Corrigeer eerst alle fouten in het bestelformulier voordat je kunt verzenden")}
                    </div>
                )}
                    <Button size="large" variant="outlined" color='primary' type="submit">{t("Verzenden")}</Button>
                </div>
            </form>
            )
        }
        </div>
    )
}
