import React from 'react';
import PurchaseRequest from '../components/PurchaseRequest';
import DeliveryAddress from '../components/PurchaseRequest/DeliveryAddress';
import AddArticles from '../components/PurchaseRequest/Articles/AddArticles';
import PurchaseRequestOverview from '../components/PurchaseRequest/Overview';
import { useLocation } from "react-router-dom";

export default function PafPage() {
    const location = useLocation();

    function content() {
        if (location.pathname === "/inkoop-aanvraag/overzicht") {
            return <PurchaseRequestOverview />
        }

        if (location.pathname.startsWith("/inkoop-aanvraag/artikelen")) {
            return <AddArticles />
        }

        if (location.pathname.startsWith("/inkoop-aanvraag/afleveradres")) {
            return <DeliveryAddress />
        }

        return <PurchaseRequest />
    }

    return content();
}
