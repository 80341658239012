const colors = {
    primaryColor: '#002c51',
    primaryColorHover: '#005CA9',
    alternateColor: '#00649f',
    primaryColorExtraLight: 'rgba(1, 78, 150, 0.05)',
    secondary: '#777679',
    disableColorGrey: '#777679',
    primaryFont: '#425466',
    secondaryBlue: '#F6F9FC',
    tertiaryBlue: '#00B4F5',
    tertiaryFont: '#FFFFFF',
    secondaryColor: '#f39200',
    secondaryColorHover: '#fbb140', // or calculate a lighter shade manually
    systemLightBorderColorIos: '#D2D2D7',
    systemLightInactiveIos: 'rgba(0, 0, 0, 0.02)'
  };

module.exports = { colors };
