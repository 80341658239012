export function getDateAndTimeString(date) {

  const day = date.getDate();
  const day2 = (day < 10 ? '0' : '') + day;
  const month = date.getMonth() + 1;
  const month2 = (month < 10 ? '0' : '') + month;
  const jaar4 = date.getYear() + 1900;
  const hh = date.getHours();
  const hh2 = ( hh < 10 ? '0' : '') + hh;
  const mm = date.getMinutes();
  const mm2 = ( mm < 10 ? '0' : '') + mm;

  return(day2 + '-' + month2 + '-' + jaar4 + '  ' + hh2 + ':' + mm2);
}

export function getDateString(date) {
  const day = date.getDate();
  const day2 = (day < 10 ? '0' : '') + day;
  const month = date.getMonth() + 1;
  const month2 = (month < 10 ? '0' : '') + month;
  const jaar4 = date.getYear() + 1900;
  return(day2 + '-' + month2 + '-' + jaar4);
}

export function convertDateFormat(dateString) {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};


export function convertIsoDateToDDMMYYY(dateIso) {
  // example of dateIso : "2024-12-31"
  const result = dateIso.substring(8, 10) + '-' + dateIso.substring(5, 7) + '-' + dateIso.substring(0, 4);
  return result;
}

export function dateCompare(date1, date2) {

  const date1Num = date1.getDate() + (date1.getMonth() + 1) * 100 + (date1.getYear() + 1900) * 10000;
  const date2Num = date2.getDate() + (date2.getMonth() + 1) * 100 + (date2.getYear() + 1900) * 10000;

   if (date1Num === date2Num) {
    return 0;
   }

   if (date1Num < date2Num) {
    return -1;
   }

   if (date1Num > date2Num) {
    return 1;
   }

}

export function getDuration(startDate, endDate) {
    if (!startDate || !endDate) {
        return '';
    }
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (isNaN(start.getTime()) || isNaN(end.getTime())) {
            return '';
        }

        const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

        return duration;
    } catch (error) {
        return '';
    }
}
