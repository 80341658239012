import React from 'react';
import Scan from '../components/scan/index';
import Overview from '../components/scan/Overview';
import { useLocation } from "react-router-dom";

export default function ScanPage() {
    const location = useLocation();

    function content() {
        if (location.pathname === "/scanner/overzicht") {
            return <Overview />
        }

        return <Scan />
    }

    return content()
}
