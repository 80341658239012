import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TextField, Select, FormControl, FormHelperText, Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';
// import { nlNL, deDE, frFR } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Controller } from "react-hook-form";
import TabPanel from "../TabPanel";
import { useTranslation } from 'react-i18next';

export default function NeedsAnalysisForm() {
    const [value, setValue] = React.useState(0);
    const [nrOfBuildings, setNrOfBuildings] = useState('');
    const { t } = useTranslation();

    const handleChangeNrOfBuildings = (event) => {
        setNrOfBuildings(event.target.value);
    };

    const { control, handleSubmit } = useForm();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const onSubmit = (data) => {
        console.log('Submit data: ');
        console.log(data);
    }

    const tabs = [];
    const tabContentList = [];

    for (let i = 0; i < nrOfBuildings; i++) {
        let building = i + 1;
        tabs.push("gebouw " + building)
        tabContentList.push(<TabPanel key={i} value={value} index={i} control={control}/>)
    }

    return (
        <div>
            <h1>{t("Behoefte inventarisatie")}</h1>

            <form className="Form" onSubmit={handleSubmit(onSubmit)}>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nl'}
            // localeText={nlNL.components.MuiLocalizationProvider.defaultProps.localeText}
            >
                <h3>{t("Projectinformatie")}</h3>
                <div>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="acc-project-info"
                        >
                        <Typography>Projectinformatie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div>
                                    <Controller
                                        name="date_aanvraag"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <DatePicker
                                                label="Datum aanvraag"
                                                format="DD-MM-YYYY"
                                                className="Textfield"
                                                variant="outlined"
                                                {...field}
                                                renderInput={(props) => <TextField
                                                    {...props}
                                                    variant="outlined"
                                                />}
                                            />
                                            <FormHelperText>Datum aanvraag</FormHelperText>
                                            </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}

                                        Aantal gemeenschappelijke ruimtes extern
                                    />
                                    <Controller
                                        name="date_deliver"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <DatePicker
                                                label="Datum Oplevering"
                                                format="DD-MM-YYYY"
                                                renderInput={(props) => <TextField {...props} />}
                                                className="Textfield"
                                                variant="outlined"
                                                {...field}
                                            />
                                            <FormHelperText>Gewenste datum oplevering calculatie</FormHelperText>
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="approval_Q_team"
                                        control={control}
                                        defaultValue='false'
                                        render={({ field }) =>
                                        <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                    />
                                                }
                                                label="Akkoord Q-team"
                                            />
                                        </FormControl>
                                        }
                                    />
                                </div>

                                <div>
                                    <Controller
                                        name="revit_drawing_ready"
                                        control={control}
                                        defaultValue='false'
                                        render={({ field }) =>
                                        <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                    />
                                                }
                                                label="Revit tekening gereed"
                                            />
                                        </FormControl>
                                        }
                                    />
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="acc-project-team"
                            >
                            <Typography>Project team</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div>
                                    <Controller
                                        name="employee_Nezzt_Lead"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Medewerker Nezzt lead"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="cost_professional"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Kostendeskundige"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="engineering"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Engineering"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="design"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Ontwerp"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="finance"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Finance"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="equans"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="EQUANS"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="acc-project-info"
                        >
                        <Typography>Raming / calculatie informatie</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div>
                                    <Controller
                                        name="project"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Project"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />

                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="customer"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Klant"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />

                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="q-number"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                            <TextField
                                                {...field}
                                                label="Q-nummer"
                                                variant="outlined"
                                                className="Textfield TextfieldLarge"
                                            />

                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="location_placement"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                            <TextField
                                                {...field}
                                                label="Locatie plaatsing"
                                                variant="outlined"
                                                className="TextfieldLarge"
                                            />

                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="bouw besluit"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <InputLabel>Bouwbesluit</InputLabel>
                                            <Select
                                                {...field}
                                                label="Bouwbesluit"
                                                variant="outlined"
                                                className="SelectFieldSmall"
                                            >
                                            <MenuItem value={0}>Tijdelijk</MenuItem>
                                            <MenuItem value={1}>Permanent</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="nr_of_years_loc1"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <TextField
                                                {...field}
                                                type = "number"
                                                InputProps={{ inputProps: { min: "0", step: "1" } }}
                                                label="Aantal jaren locatie 1"
                                                variant="outlined"
                                                className="Textfield TextfieldLarge"
                                            />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="buy_rent"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <InputLabel>Huur / koop</InputLabel>
                                            <Select
                                                {...field}
                                                label="Huur / koop"
                                                variant="outlined"
                                                className="SelectFieldSmall"
                                            >
                                                <MenuItem value={0}>Huur</MenuItem>
                                                <MenuItem value={1}>Koop</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="opl_opp"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <InputLabel>OPL / OPP</InputLabel>
                                            <Select
                                                {...field}
                                                label="OPL / OPP"
                                                variant="outlined"
                                                className="SelectFieldSmall"
                                            >
                                                <MenuItem value={0}>OPL</MenuItem>
                                                <MenuItem value={1}>OPP</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="installation_concept"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <InputLabel>Installatieconcept</InputLabel>
                                            <Select
                                                {...field}
                                                label="Installatieconcept"
                                                variant="outlined"
                                                className="SelectFieldSmall"
                                            >
                                                <MenuItem value={0}>Tijdelijk</MenuItem>
                                                <MenuItem value={1}>Permanent BENG</MenuItem>
                                                <MenuItem value={2}>NOM</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="nr_of_building_layers_highest_part"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <TextField
                                                {...field}
                                                type = "number"
                                                InputProps={{ inputProps: { min: "0", step: "1" } }}
                                                label="Aantal bouwlagen hoogste deel"
                                                variant="outlined"
                                                className="TextfieldLarge"
                                            />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="common_area_external"
                                        control={control}
                                        defaultValue='false'
                                        render={({ field }) =>
                                        <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...field}
                                                    />
                                                }
                                                label="Gemeenschappelijke ruimte extern"
                                            />
                                        </FormControl>
                                        }
                                    />
                                </div>

                                <div>
                                    <Controller
                                        name="afwerkings_niveau"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>
                                            <InputLabel>Afwerkingsniveau</InputLabel>
                                            <Select
                                                {...field}
                                                label="Afwerkingsniveau"
                                                variant="outlined"
                                                className="SelectFieldSmall"
                                            >
                                            <MenuItem value={0}>Casco</MenuItem>
                                            <MenuItem value={1}>Casco +</MenuItem>
                                            <MenuItem value={2}>Gestoffeerd</MenuItem>
                                            <MenuItem value={3}>Gemeubileerd</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        name="preferred_material_cascade_cladding"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) =>
                                        <FormControl>

                                        <TextField
                                            {...field}
                                            label="Voorkeur materiaal gevelbekleding"
                                            variant="outlined"
                                            className="Textfield TextfieldLarge"
                                        />
                                        </FormControl>
                                        }
                                        rules={{
                                            // required: true
                                        }}
                                    />
                                </div>

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <FormControl>
                    <InputLabel>Aantal Gebouwen</InputLabel>
                    <Select
                        value={nrOfBuildings}
                        onChange={handleChangeNrOfBuildings}
                        label="Aantal gebouwen"
                    >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                    </Select>
                </FormControl>
                </LocalizationProvider>
                { nrOfBuildings > 0 ?
                <div className="building-tabs">
                    <div className="building-tabs-header">
                        <Tabs value={value} onChange={handleChange} aria-label="Aantal gebouwen">
                            {tabs.map((tab, index) => (
                                <Tab key={index} label={tab} id={`building-tabpanel-${index}`} aria-controls={`building-tab-${index}`}/>
                            ))}
                        </Tabs>
                    </div>
                    {tabContentList}
                </div>
                : null
                }

                <div>
                     <button type='submit'>Opslaan</button>
                </div>
                </form>
                {/* Gewenste datum oplevering calculatie */}
        </div>
    )
}
