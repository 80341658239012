import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Button from '../components/ui/Button';

export default function AreaType(props) {
    const { id } = useParams();
    const [area, setArea] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/demeeuw/areatype/" + id);
                setArea(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
    }, [id])

    return (
        <div>
            <h1>RuimteSoort: {area?.name}</h1>
            <form className="Form">
                <div>
                    <Controller
                        // name={"name"}
                        // control={control}
                        defaultValue={null}
                        render={({ field }) =>
                            <TextField
                                // id="name"
                                label="Naam"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <Controller
                        name={"color"}
                        // control={control}
                        defaultValue={null}
                        render={({ field }) =>
                            <TextField
                                id="color"
                                label="Kleur"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <Button>Opslaan</Button>
                </div>
            </form>
        </div>
    )
}
