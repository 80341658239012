import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectIsahArticle({vendorId, partCode, onSelectArticle})   {

    const [articleDropDownList, setArticleDropDownList] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState(null);

    useEffect(() => {
      
        if (!partCode) {
            // select all articles
            const fetchData = async () => {
                try {
                    const articlesFromDb = await axios.get(process.env.REACT_APP_API_URL + '/isah/article/getListByVendorId/' + vendorId);
                    articlesFromDb.data.sort( (a, b) => a.Description.localeCompare(b.Description));
                    setArticleDropDownList(articlesFromDb.data);
                } catch (error) {
                    console.error('Kan geen artikelen ophalen voor deze leverancier: ', error);
                }
            };
            fetchData();
        } else {
            // select 1 particular article
            const fetchData = async () => {
                try {
                    const articleFromDb = await axios.get(process.env.REACT_APP_API_URL + '/isah/article/get/' + partCode);
                    setArticleDropDownList(articleFromDb.data);
                    const article = articleFromDb.data[0];
                    setSelectedArticle(article);
                    handleArticle(article);
                } catch (error) {
                    console.error('Kan geen artikel ophalen voor deze artikelcode: ', error);
                }
            };

            fetchData();
        }
    }, []);

    const handleArticle = (article) => {
        setSelectedArticle(article);

        if (!article) {
          setSelectedArticle(null);
          onSelectArticle(null);
          return;
        }
        setSelectedArticle(article);
        onSelectArticle(article);
    }

    return (
        <>
            <Autocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    disableClearable = {partCode !== null}
                    id="article-auto-complete"
                    options={articleDropDownList}
                    getOptionLabel = { (art) =>
                        art.PartCode
                        ? art.Description  + ' - ' + art.PartCode.trim() + ' - Lev.artik.nr: ' + art.VendPartCode
                        : 'Leeg'
                    }
                    sx={{ width: 400 }}
                    onChange={(event, article) => {
                        handleArticle(article);
                    }}
                    value= {selectedArticle || null}
                    renderInput={(params) =>
                        <TextField {...params}
                            label='Selecteer ISAH-artikel'
                        />
                    }
                />
        </>
    );
}
