import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from 'axios';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { nlNL } from '@mui/x-data-grid-pro/locales';
import { uc } from '../../util';
import { convertDateFormat } from '../../util/dateHelper';
import { useTranslation } from "react-i18next";

const dataGridColumns = [
    {
        field: 'Inkooporder',
        headerName: 'Inkooporder',
        width: 200,
    },
    {
        field: 'PurQuotNr',
        headerName: 'Inkoopofferte',
        width: 170,
    },
    {
        field: 'Soort',
        headerName: 'Soort',
        width: 100,
    },
    {
        field: 'Orderdatum',
        headerName: 'Orderdatum',
        width: 150,
        renderCell: (params) => convertDateFormat(params.value),
    },
    {
        field: 'Leverancier',
        headerName: 'Leverancier',
        width: 100,
    },
    {
        field: 'Naam verzendadres',
        headerName: 'Naam verzendadres',
        width: 200,
    },
    {
        field: 'Referentie',
        headerName: 'Referentie',
        width: 180,
    },
    {
        field: 'Printstatus order',
        headerName: 'Printstatus order',
        width: 120,
    },
    {
        field: 'Printstatus offerte',
        headerName: 'Printstatus offerte',
        width: 120,
    },
    {
        field: 'Laatst bijgewerkt door',
        headerName: 'Laatst bijgewerkt door',
        width: 200,
    },
    {
        field: 'Inkoper',
        headerName: 'Inkoper',
        width: 100,
    },
    {
        field: 'Offertereferentie',
        headerName: 'Offertereferentie',
        width: 200,
    },
    {
        field: 'Klant',
        headerName: 'Klant',
        width: 100,
    },
    {
        field: 'Afleveradres',
        headerName: 'Afleveradres',
        width: 100,
    },
    {
        field: 'Klantnaam',
        headerName: 'Klantnaam',
        width: 200,
    },
    {
        field: 'Klantnaam afleveradres',
        headerName: 'Klantnaam afleveradres',
        width: 230,
    },
];

const PurchaseDocumentList = () => {
    const [list, setList] = useState([]);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleRowDoubleClick = (params, event) => {
        navigate(`/inkooporder/${params.id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + "/isah/purchasedocument");
                setList(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <h1>{uc(t('inkooporder'))}</h1>
            <div style={{ height: '80vh'}}>
                <DataGridPro
                    getRowId={(row) => row.Inkooporder}
                    rows={list}
                    headerFilters
                    disableColumnFilter
                    columns={dataGridColumns}
                    loading={list.length === 0}
                    localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                    pagination
                    pageSizeOptions={[30, 60, 90]}
                    paginationMode="client"
                    disableVirtualization={false}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 30,
                                page: 0,
                            },
                        },
                    }}
                    onRowDoubleClick={handleRowDoubleClick}
                />
            </div>
        </div>
    );
};

export default PurchaseDocumentList;
