import React from 'react';
import PurchaseDocument from '../components/PurchaseDocument/item';
import PurchaseDocumentList from '../components/PurchaseDocument/list';
import { useLocation } from "react-router-dom";

export default function PurchaseDocumentPage() {
    const location = useLocation();

    function content() {
        if (location.pathname.startsWith("/inkooporder/")) {
            return <PurchaseDocument />
        }

        return <PurchaseDocumentList />
    }

    return content();
}
