import React, { useEffect, useState } from 'react';
import { uc } from '../../util'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import useFetchData from '../../util/useFetchData';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { nlNL } from '@mui/x-data-grid-pro/locales';
import Button from '../../components/ui/Button';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import Popup from '../ui/Popup';

export default function GrabStock({title}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const account = accounts[0];
    const [popupActive, setPopupActive] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [popupText, setPopupText] = useState("");
    const [popupTitle, setPopupTitle] = useState("");
    const [selectedId, setSelectedId] = useState();

    const { data: fetchedList, error, loading } = useFetchData('/demeeuw/grabstock');
    const { data: fetchedList2, error2, loading2 } = useFetchData('/isah/checkstock/grabstockarticle');
    const [list, setList] = useState([]);
    const [list2, setList2] = useState([]);
    const paneList = [
        'Grijpvoorraad',
        'Benodigd',
    ];

    useEffect(() => {
        if (fetchedList) {
            setList(fetchedList);
        }

        // List2 should only contain items where the PartCode is not in the list of article_ids in fetchedList
        const partcodes = fetchedList.map(item => item.article_id);

        // filter list and add unique id to each item
        const filteredList2 = fetchedList2
            .filter(item => !partcodes.includes(item.PartCode))
            .map((item, index) => ({
                ...item,
                id: index
            }));

        setList2(filteredList2);
    }, [fetchedList, fetchedList2]);

    const hasRole = (account, roles) => roles.some(role => account?.idTokenClaims?.roles?.includes(role));
    const writeRights = hasRole(account, ["GrabStock.Write"]);

    const handleEditClick = (row) => {
        navigate(`/grabstock/${row.id}`, { state: { part: row } });
    };

    const handleDelete = (id) => {
        setPopupTitle(t("Verwijderen"));
        setPopupText(t("Weet u het zeker?"));
        setPopupActive(true);
        setSelectedId(id)
    }

    const popupCallback = (type) => {
        setPopupText("");
        setPopupActive(false);
        const id = selectedId;
        setSelectedId(null);
        if (type === 'confirm' && selectedId) {
            axios.delete(
                process.env.REACT_APP_API_URL + "/demeeuw/grabstock/" + selectedId,
            ).then(response => {
                if (response.status === 200) {
                    setList(prevList => prevList.filter(item => item.id !== id));
                }
            }).catch(error => {
                console.error("Error: ", error)
            }).finally(() => {

            })
        }
    }

    function TabPanel({ children, value, index }) {
        return (
            <div className="tabpanel-panel" hidden={value !== index}>
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    function handleTabChange(event) {
        setActiveTab(Number(event.target.value))
    }

    const dataGridColumns = [
        {
            field: 'article_id',
            headerName: 'Artikelnr',
            width: 120,
        },
        {
            field: 'description',
            headerName: 'Omschrijving',
            width: 160,
        },
        {
            field: 'locationSegment',
            headerName: 'Segment',
            width: 160,
            valueGetter: (value, row) => {
                const location = row.location || '';
                const segment = row.segment || '';
                return `${location} ${segment}`.trim();
            }
        },
        {
            field: 'amount',
            headerName: 'Aantal in bak',
            width: 160,
        },
        {
            field: 'unit',
            headerName: 'Eenheid',
            width: 160,
        },
        {
            field: 'container_type',
            headerName: 'Soort bak',
            width: 160,
        },
        {
            field: 'number_of_containers',
            headerName: 'Aantal bakken',
            width: 160,
        },
    ];

    const dataGridColumns2 = [
        {
            field: 'PartCode',
            headerName: 'Artikelnr',
            width: 200,
        },
        {
            field: 'Description',
            headerName: 'Omschrijving',
            width: 400,
        },
        {
            field: 'capaciteitsgroep',
            headerName: 'Capaciteitsgroep',
            width: 200,
        }
    ];

    if (writeRights) {
      dataGridColumns.unshift({
          field: 'actions',
          headerName: '',
          width: 100,
          renderCell: (params) => (
              <>
                  <IconButton onClick={() => handleEditClick(params.row)} aria-label="edit">
                      <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(params.row.id)} aria-label="delete">
                      <DeleteIcon />
                  </IconButton>
              </>
          ),
          sortable: false,
          filterable: false,
      });
    }

    return (
        <>
            <h1>{uc(title)}</h1>

            {writeRights &&
              <div>
                <Button onClick={() => navigate('/grabstock/new')}>Voeg grijpvoorraad toe <span>+</span></Button>
              </div>
            }
            {/** I want a tabbed view here */}
            <div className="panel tabpanel" style={{backgroundColor: 'white'}}>
                <div className="tabpanel-header tabpanel-desktop">
                    {
                        paneList.map((pane, key) => {
                            return <button key={key} className={ activeTab === key ? "active" : ""} onClick={() => setActiveTab(key)}>{pane}</button>
                        })
                    }
                </div>
                <div className="tabpanel-header tabpanel-mobile">
                    <select value={activeTab} onChange={handleTabChange}>
                        {
                            paneList.map((pane, key) => {
                                return <option key={key} value={key}>{pane}</option>
                            })
                        }
                    </select>
                </div>


                <TabPanel value={activeTab} index={0}>
                    {loading && <div className="loader">Loading...</div>}
                    {error && <div>Fout: {error.message}</div>}
                    {!loading && !error && (
                    <div style={{ height: 800, width: '100%' }}>
                        <DataGridPro
                            getRowId={(row) => row.id}
                            localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                            headerFilters
                            disableColumnFilter
                            // pagination
                            paginationMode="client"
                            rows={list}
                            columns={dataGridColumns}
                        />
                    </div>
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <DataGridPro
                        getRowId={(row) => row.id}
                        localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
                        headerFilters
                        disableColumnFilter
                        // pagination
                        paginationMode="client"
                        rows={list2}
                        columns={dataGridColumns2}
                    />
                </TabPanel>
            </div>

            <Popup
                active={popupActive}
                title={popupTitle}
                text={popupText}
                callback={popupCallback}
                confirmText={t("ja")}
                cancelText={t("nee")}
            />
        </>
    )
}
