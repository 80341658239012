import React from 'react';
import { determineTextLines } from '../../util/stringHelper';

export default function PafBasicInformation({purchReqStructure}) {
    const generalDescription = () => {
        if (purchReqStructure.project.vNumber) {
            return 'Project ' + purchReqStructure.project.fullDescription
        }
        if (purchReqStructure.costCenter.code) {
            return 'Kostenplaats ' + purchReqStructure.costCenter.fullDescription
        }
    }

    const requestTypeDescription = (purchReqStructure) => {
        if (purchReqStructure.requestType === 'cost') {
            return 'Type verzoek: Kosten';
        } else  if (purchReqStructure.requestType === 'project') {
            return 'Type verzoek: Project';
        }  else  if (purchReqStructure.requestType === 'investment-application') {
            return 'Type verzoek: Investeringsaanvraag (GBK0291)';
        }
    }

    const vendorNameAndAddress = () => {
        return purchReqStructure.vendor.name + ' - ' + purchReqStructure.vendor.addr + ' - ' +  purchReqStructure.vendor.city;
    }

    return (
        <>
            <h4>{requestTypeDescription(purchReqStructure)}</h4>
            <h4>{generalDescription()}</h4>
            <h4>Leverancier: {vendorNameAndAddress()}</h4>
            { purchReqStructure.deviatingVendorAllowed ?
                <h4>Afwijkende leverancier mogelijk</h4>
            :
                <h4>Afwijkende leverancier niet mogelijk</h4>
            }
            <br />
            { purchReqStructure.deliveryAddress.option !== 'notAppl' ?
                <div>
                    <p><strong>Afleveradres:</strong></p>
                    { determineTextLines(purchReqStructure.deliveryAddress.address).map((textLine , key) => <h4 key={key}>{textLine}</h4>) }
                    { purchReqStructure.deliveryAddress.instruction.trim() !== '' ?
                        <div>
                            <p><strong>Afleverinstructie:</strong></p>
                            {determineTextLines(purchReqStructure.deliveryAddress.instruction).map((textLine, i) => <h4 key = {i}>{textLine}</h4>)}
                        </div>
                    :
                        null
                    }
                </div>
            :
                <div>
                    <h4>Afleveradres en -instructie niet van toepassing</h4>
                </div>
            }
        </>
    )
}
