module.exports = function (gantt) {
  gantt.skins.skyblue = {
    config: {
      grid_width: 350,
      row_height: 27,
      scale_height: 27,
      link_line_width: 1,
      link_arrow_size: 8,
      lightbox_additional_height: 75
    },
    _second_column_width: 95,
    _third_column_width: 80
  };
};