import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Html5Qrcode } from 'html5-qrcode';

export default function Camera() {
	const [reader, setReader] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
    const [isScannerRunning, setIsScannerRunning] = useState(false);
    const html5QrCodeRef = useRef(null);

	const {t} = useTranslation();

	const config = { fps: 10, qrbox: { width: 500, height: 500 } };


	useEffect(() => {
        if (html5QrCodeRef.current) {
			// If the scanner is already initialized, don't initialize again.
			return;
		}

		const html5QrCode = new Html5Qrcode("qr-reader");
        html5QrCodeRef.current = html5QrCode;
      //  let isScannerRunning = false;

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			setReader(decodedText)
            html5QrCode.stop().then(() => {
                setIsScannerRunning(false);
                html5QrCodeRef.current = null;
            }).catch((err) => {
                console.error("Failed to stop QR Code scanner.", err);
            });
		};

		html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback)
			.then(() => {
                setIsScannerRunning(true);
			})
			.catch((err) => {
				console.error("Failed to start QR Code scanner.", err);
				setErrorMessage(err.message);
			});

		return () => {
			if (isScannerRunning && html5QrCodeRef.current) {
                html5QrCodeRef.current.stop().then(() => {
                        setIsScannerRunning(false);
                        html5QrCodeRef.current = null;
				}).catch((err) => {
					console.error("Failed to stop QR Code scanner during cleanup.", err);
				});
			}
		};
	});

	// useEffect(() => {
	// 	if (reader) {
	// 		setScannedText(reader);
	// 	}
	// }, [reader]);


	return (
		<>
			<div className="content">
				<h1>{t('scan')}</h1>
			</div>
            <div>
                {reader}
            </div>
			<div className="content">
				<div id="qr-reader" style={{ width: '500px', height: '500px' }}></div>
				<div className="error-message">{errorMessage}</div>
			</div>
		</>
	)
}
