import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';


export default function UserList() {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(process.env.REACT_APP_API_URL + "/users");
            setUserList(response.data);
        } catch (error) {
            console.error("Error fetching posts:", error);
          }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>Gebruikers</h1>
            <div className="listview">
                <div className="listview-item listview-header">
                    <div>
                        <span><strong>Naam</strong></span>
                    </div>
                    <div>
                        <span><strong>Email</strong></span>
                    </div>
                    <div className="icon"></div>
                </div>
                {
                    userList.map(user => {
                    return (
                        <div className="listview-item">
                            <div>
                                <span>{user.name}</span>
                            </div>
                            <div>
                                <span>{user.email}</span>
                            </div>
                            <div className="icon">
                                <div className="icon-button"><EditIcon/></div>
                            </div>
                        </div>

                    )})
                }
            </div>
        </div>
    )

}
