import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { formatPrice, parseISODate } from '../util/index'
import { DataGridPro } from '@mui/x-data-grid-pro';

export default function QuoteList() {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const pageSize = 50;

    const columns = [
      {
        field: 'id',
        headerName: 'Id',
        width: 100,
      },
      {
        field: 'name',
        headerName: 'Quote',
        width: 250,
        renderCell: (params) => <Link to={`/offerte/${params.row.id}`}>{params.row.name}</Link>
      },
      {
        field: 'description',
        headerName: 'Omschrijving',
        width: 400,
      },
      {
        field: 'owner',
        headerName: 'Eigenaar',
        width: 150,
      },
      {
        field: 'totalPrice',
        headerName: 'Totaal',
        width: 150,
        valueGetter: (params) => formatPrice(params.row.totalPrice)
      },
      {
        field: 'state',
        headerName: 'Status',
        width: 150,
        renderCell: (params) => params.row.state ? params.row.state : <i>Unsubmitted</i>
      },
      {
        field: 'modifiedDate',
        headerName: 'Laatst bewerkt',
        width: 150,
        valueGetter: (params) => ({
          date: params.row.modifiedDate,
          displayDate: parseISODate(params.row.modifiedDate),
        }),
        sortComparator: (v1, v2) =>
          new Date(v1.date).getTime() - new Date(v2.date).getTime(),
        renderCell: (params) => params.value.displayDate,
      },
    ];

    useEffect(() => {
      let isMounted = true;
      const fetchData = async () => {
        setLoading(true);
        try {
          const quoteResponse = await axios.post(process.env.REACT_APP_API_URL + "/kbmax/search", {
            "sortField": "modifiedDate",
            "descending": true,
            "take": pageSize,
            "skip": page * pageSize,
            "fields":
              ["id",
              "state",
              "ownedBy",
              "idCustomer","idContact","name","description","currency","createdDate","modifiedDate","owner","customer","contact","products.id","products.idQuote","products.quote","products.currency","products.isConfigured","products.price","products.createdDate","products.modifiedDate","products.createdBy","products.name","products.description","products.shortDescription","products.sku","products.imagePath","files.tags.id","files.tags.name","files.id","files.name","files.source","files.status","files.filePath","totalPrice"],
          });

          if (isMounted) {
            const fetchedData = quoteResponse.data;
            setList(prevRows => [...prevRows, ...fetchedData]);
            setRowCount(prevRowCount => prevRowCount + fetchedData.length);
          }

        } catch (error) {
          console.error("Error:", error);
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      };

      fetchData();
      return () => {
        isMounted = false;
      };
    }, [page]);

    const handleScrollEnd = (params) => {
      if (!loading) {
        setPage(prevPage => prevPage + 1); // Load the next page when scroll reaches end
      }
    };

    return (
      <div className="list" style={{height: '85vh'}}>
      <DataGridPro
        rows={list}
        getRowId={(row) => row.id}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'data-grid-row-even' : 'data-grid-row-odd'
        }
        pageSize={pageSize}
        hideFooterPagination={true}
        rowCount={rowCount}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onRowsScrollEnd={handleScrollEnd}
        loading={loading}
        sortModel={[
          {
            field: 'modifiedDate', // replace 'columnName' with the field name you want to sort by
            sort: 'desc', // 'asc' for ascending and 'desc' for descending
          },
        ]}
      />
      </div>
    )
}
