import {getDateAndTimeString} from '../../../util/dateHelper';
import {convertIsoDateToDDMMYYY} from '../../../util/dateHelper';
import { formatNumber} from '../../../util/index';
import {constDeMeeuwAddress} from '../../constants/constants';

export const constructPurchReqTextLines = (accountname, purchReqStructure) => {

    const textManualArticle = (article) => {
        let text = '';
        text +=  'Omschrijving: ' + article.description + '\n';
        if (article.gbkNumber) {
            text +=  'GBK-nummer: ' + article.gbkNumber + ' - ';
            text +=  article.gbkNumberDescription + '\n';
        }
        if (article.spNumber) {
            text +=  'SP-nummer: ' + article.spNumber + ' - ';
            text +=  article.spNumberDescription + '\n';
        }
            text +=  'Aantal: ' + article.number + '\n';
            text +=  'Stukprijs ex BTW: ' + article.priceDescription + '\n';
            text +=  'Totaalbedrag: ' + article.totalPriceDescription + '\n';
            text +=  'Afleverdatum: ' + convertIsoDateToDDMMYYY(article.deliveryDateIso) + '\n';
            text += '\n';
        if (article.remark !== '') {
            text += 'Opmerking: \n' + article.remark + '\n';
        }
        text += '___________________________';
        text += '\n';
        return text;
    }


    const textIsahArticle = (article) => {
        let text = '';
        text +=  'Artikel nr: ' + article.articleNumberDescription + '\n';
        text +=  'Leverancier artikel nr: ' + article.vendPartCode + '\n';
        text +=  'Omschrijving: ' + article.description + '\n';
        text += 'Aantal: ' + article.numberDescription + '\n';
        text += 'Stukprijs ex BTW: ' + article.priceDescription + '\n';
        text += 'Totaal bedrag: ' + article.totalPriceDescription + '\n';
        text +=  'Afleverdatum: ' + convertIsoDateToDDMMYYY(article.deliveryDateIso) + '\n';
        text += '\n';
        if (article.remark !== '') {
            text += 'Opmerking: \n' + article.remark + '\n';
        }
        text += '___________________________';
        text += '\n';
        return text;
    }

    const manualArticles = purchReqStructure.articles.filter(art => art.manualArticle).map( ({...elem}) => { return elem});
    const isahArticles = purchReqStructure.articles.filter(art => !art.manualArticle).map( ({...elem}) => { return elem});

    const textLines = [];

    textLines.push(`Aangevraagd door: ${accountname} \n\n`);
    textLines.push(`Datum en tijdstip: ${getDateAndTimeString(new Date())} \n\n`);

    textLines.push(`Leverancier: ${purchReqStructure.vendor.name} \n`);
    textLines.push(`Crediteurnummer: ${purchReqStructure.vendor.vendid} \n`);
    textLines.push(`Adres: ${purchReqStructure.vendor.addr}, ${purchReqStructure.vendor.postcode} ${purchReqStructure.vendor.city}\n\n`);

    if (purchReqStructure.deviatingVendorAllowed) {
        textLines.push('Afwijkende leverancier is toegestaan \n\n');
    } else {
        textLines.push('Afwijkende leverancier is niet toegestaan \n\n');
    }

    if (purchReqStructure.deliveryAddress.option === 'notAppl') {
        textLines.push(`Afleveradres: niet van toepassing \n\n`);
    } else if (purchReqStructure.deliveryAddress.option === 'dmo') {
        textLines.push(`Afleveradres:\n${constDeMeeuwAddress} \n\n`);
    } else if (purchReqStructure.deliveryAddress.option === 'alt') {
        textLines.push(`Afleveradres:\n${purchReqStructure.deliveryAddress.address} \n\n`);
    }

    if (purchReqStructure.deliveryAddress.option === 'dmo' || purchReqStructure.deliveryAddress.option === 'alt') {
        textLines.push(`Afleverinstructie:\n${purchReqStructure.deliveryAddress.instruction}\n\n`);
    }

    if (purchReqStructure.requestType === 'cost') {
        textLines.push(`Kostenplaats: ${purchReqStructure.costCenter.fullDescription}\n\n`);
    } else if (purchReqStructure.requestType === 'project') {
        textLines.push(`Project: ${purchReqStructure.project.fullDescription}\n\n`);
    } else if (purchReqStructure.requestType === 'investment-application') {
        textLines.push(`Investeringsaanvraag: ${purchReqStructure.project.fullDescription}\n\n`);
    }

    textLines.push(`Opmerking orderniveau: ${purchReqStructure.totalOrderRemark} \n\n`);

    textLines.push(`Aantal handmatige artikelen: ${manualArticles.length} \n`);
    textLines.push(`Aantal ISAH-artikelen: ${isahArticles.length} \n\n`);
    textLines.push(`Totaalprijs handmatige artikelen: ${formatNumber(purchReqStructure.totalPriceManualArticles, 2, 2)} \n`);
    textLines.push(`Totaalprijs ISAH-artikelen: ${formatNumber(purchReqStructure.totalPriceIsahArticles, 2, 2).toString()} \n`);
    textLines.push(`Totaalprijs alle artikelen: ${formatNumber(purchReqStructure.totalGeneralPrice, 2, 2)} \n\n`);
    textLines.push('-----------------------------------------------------------------------------------------------------------\n');

    let totalText = '';
    for (let i = 0; i < textLines.length; i++) {
        totalText += textLines[i]
    }

    manualArticles.sort( (a, b) => a.description.localeCompare(b.description));
    isahArticles.sort( (a, b) => a.description.localeCompare(b.description));

    if (manualArticles.length > 0) {
        totalText += '\n\n';
        totalText += 'Handmatige artikelen:\n';
        totalText += '_________________________________________________\n\n';

        for (let i = 0; i < manualArticles.length; i++) {
            let article = manualArticles[i];
            totalText+= textManualArticle(article);
        }
    }

    if (isahArticles.length > 0) {
        totalText += '\n\n';
        totalText += 'ISAH-artikelen:\n';
        totalText += '_________________________________________________\n\n';

        for (let i = 0; i < isahArticles.length; i++) {
            let article = isahArticles[i];
            totalText+= textIsahArticle(article);
        }
    }

    return totalText;
}
